<template>
  <div class="col-lg-12 px-0 home-slider">
    <div class="slider-img" v-touch:swipe="onSwipe" v-if="!getting">
      <vueper-slides
        ref="homeSlides"
        :touchable="false"
        class="h-100"
        :autoplay="true"
        :duration="5000"
      >
        <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide" />
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { mapGetters } from "vuex";
export default {
  props: {
    sliderType: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["isLg"]),
  },
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: [],
      getting: true,
      timer: null,
      currentIndex: 0,
    };
  },
  created() {
    this.getSliders();
  },
  methods: {
    getSliders() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("slider");
      if (cacheData) {
        const slidesObj = cacheData.filter(
          (data) => data.slug == this.sliderType
        )[0]["slider-images"];
        var slider = [];

        for (const [value] of Object.entries(slidesObj)) {
          slider.push(value.url);
          this.$store.commit("preloadImage", value.url);
        }
        this.slides = slider;

        this.getting = false;
      }
      this.$get.getFreshData("slider").then((data) => {
        const slidesObj_ = data.filter(
          (data_) => data_.slug == this.sliderType
        )[0]["slider-images"];
        var slider = [];
        for (const [key, value] of Object.entries(slidesObj_)) {
          console.log(key);
          slider.push(value.url);
          this.$store.commit("preloadImage", value.url);
        }
        this.slides = slider;
        this.getting = false;
      });
    },
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.homeSlides.previous();
      } else if (direction === "left") {
        this.$refs.homeSlides.next();
      }
    },
  },
};
</script>

<style >
.home-slider .vueperslides__bullets {
  bottom: 50px !important;
}
.home-slider .vueperslides__bullet.vueperslides__bullet--active .default {
  background-color: rgb(var(--white), 1) !important;
  width: 25px !important;
}
.home-slider .vueperslides__bullet .default {
  background-color: rgb(var(--white), 0.4) !important;
  border: none !important;
}
.vueperslides__parallax-wrapper,
.vueperslides__inner {
  height: 100%;
}
.slider-img {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.home-slider button.vueperslides__arrow--prev {
  padding: 5px !important;
  background: linear-gradient(90deg, rgb(var(--primary)), transparent);
  height: 100%;
  border-radius: 0px;
  width: 15% !important;
  left: 0px !important;
  padding-right: 10% !important;
}
.home-slider button.vueperslides__arrow--next {
  padding: 5px !important;
  background: linear-gradient(90deg, transparent, rgb(var(--primary)));
  height: 100%;
  border-radius: 0px;
  width: 15% !important;
  right: 0px !important;
  padding-left: 10% !important;
}

button.vueperslides__arrow svg {
  stroke-width: 2px !important;
  width: 15px !important;
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  position: absolute;
  bottom: 50px;
  right: 0px;
  z-index: 1;
  left: 0px;
}
.carousel__pagination-button--active {
  background-color: rgb(var(--gray-1)) !important;
  width: 15px !important;
}
.carousel__pagination-button {
  margin: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 0;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  background-color: rgb(var(--gray-1), 0.4);
}
.pagination .dot {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  background: rgb(var(--white), 0.4);
  margin: 0px 5px;
  transition: 0.3s all ease-in-out;
}
.pagination .dot.active-dot {
  width: 25px;
  background: rgb(var(--white), 0.8);
}
.pagination {
  position: absolute;
  bottom: 50px;
  left: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
/* ------------------------------------------------"desktop"------------------------------------------------ */
@media (min-width: 1200px) {
  .el-carousel__container {
    height: calc(100vh - 100px) !important;
  }
  .el-carousel__indicators--horizontal {
    margin-bottom: 5vh !important;
  }
}
</style>