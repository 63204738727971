import axios from "axios";
import { readFromCache, writeToCache } from "../util/cache";
import { url } from "../constant/data";
axios.defaults.baseURL = url;
const getFreshData = async (url) => {
  const { data } = await axios.get(url);
  writeToCache(url, data);
  return data;
};

const getCachedData = (url) => readFromCache(url);

export default { getCachedData, getFreshData };
