<template>
  <div class="col-lg-12 pt-0 bg-padding">
    <div class="top-border-primary mb-sm-2"></div>
    <p class="lg-title">{{ text }}</p>
    <div class="row bg-padding px-0">
      <!-- <div class="col-lg-6" v-if="isLg"> -->
      <div class="col-lg-6">
        <div class="collaspe-img">
          <transition name="fade">
            <img
              :src="activeName ? activeImage : defaultImg"
              class="w-100"
              alt=""
              :key="activeName ? activeImage : defaultImg"
            />
          </transition>
        </div>
      </div>
      <div class="col-lg-6">
        <accordion
          v-model="activeName"
          :content="filterAccordion($route.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import accordion from "./accordion.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    defaultImg: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  components: {
    accordion,
  },
  data() {
    return {
      // windowWidth: document.documentElement.clientWidth,
      // windowHeight: document.documentElement.clientHeight,
      activeName: null,
    };
  },
  methods: {
    // setDimensions() {
    //     this.windowWidth = document.documentElement.clientWidth;
    //     this.windowHeight = document.documentElement.clientHeight;
    // },
    // beforeDestroy() {
    // window.removeEventListener('resize', this.setDimensions);
    // },
  },
  mounted() {
    // window.addEventListener('resize', this.setDimensions);
  },
  computed: {
    ...mapGetters(["isLg", "filterAccordion"]),
    activeImage() {
      return this.filterAccordion(this.$route.name).filter(
        (collaspe) => collaspe.title == this.activeName
      )[0].image.url;
    },
  },
  
};
</script>

<style scoped>
.collaspe-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  max-height: 375px;
}
.collaspe-img {
  height: 347px;
  position: relative;
  width: 100%;
}
</style>