<template>
  <div class="row" v-if="!getting">
    <div class="header-height"></div>
    <div class="col-lg-12 bg-padding">
      <div class="row">
        <div class="col-lg-3 mb-sm-5 col-md-3">
          <p class="lg-title secondary">
            <span v-html="post['section1-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 col-md-6">
          <p class="sm-sub-title mb-3">
            <span v-html="post['section1-p1']"></span>
          </p>
          <p class="sm-sub-title secondary">
            <span v-html="post['section2-p2']"></span>
          </p>
        </div>
        <div
          class="col-lg-3 my-sm-5 col-md-3 d-flex position-relative"
          v-touch:swipe="onSwipe"
        >
          <div v-scroll-ani class="scroll-ani top">
            <svg
              width="16"
              height="16"
              class="scroll-ani-circle"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="8"
                r="7"
                stroke="rgb(var(--text))"
                stroke-width="2"
              />
            </svg>

            <svg
              width="2"
              height="90"
              class="scroll-ani-line"
              viewBox="0 0 2 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="4.37114e-08"
                x2="0.999996"
                y2="100"
                stroke="rgb(var(--text))"
                stroke-width="2"
              />
            </svg>
          </div>
          <vueper-slides
            :dragging-distance="50"
            :touchable="false"
            ref="inno"
            class="w-100 dark-dots no-shadow facts-slide"
            :arrows="false"
            :autoplay="true"
          >
            <vueper-slide
              v-for="item in filterFacts($route.name)"
              :title="item.title"
              :key="item.title"
            >
              <template #content>
                <div class="vueperslide__content-wrapper">
                  <div class="ml-4 fact" :key="item.item">
                    <p class="title">
                      <span v-html="item.title"></span>
                    </p>
                    <p class="normal-text">
                      <span v-html="item._content"></span>
                    </p>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </div>
    <collaspe
      :text="post['section2-title']"
      :defaultImg="post['according-feature-image'].url"
    />

    <recentBusiness tag="Innovative Finance" />
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
import recentBusiness from "../common/recentBusiness.vue";
import collaspe from "../common/collaspe.vue";
import Footer from "../common/footer.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import { mapGetters } from "vuex";
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Innovation Finance",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      factsInterval: null,
      post: {},
      getting: true,
    };
  },
  components: {
    recentBusiness,
    collaspe,
    Footer,
    VueperSlides,
    VueperSlide,
  },
  computed: {
    ...mapGetters(["isLg", "filterFacts"]),
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("innovative-finance");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("innovative-finance").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.inno.previous();
      } else if (direction === "left") {
        this.$refs.inno.next();
      }
    },
  },
};
</script>

<style scoped>
.innovation-img img {
  max-height: 347px;
  object-fit: cover;
}
</style>