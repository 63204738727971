<template>
  <div class="row" v-if="!getting">
    <div class="header-height"></div>
    <div class="col-lg-12 bg-padding">
      <div class="row">
        <div class="col-lg-3 mb-sm-5 col-md-3">
          <p class="lg-title secondary">
            <span v-html="post['section1-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 col-md-6">
          <p class="sm-sub-title mb-3">
            <span v-html="post['section1-p1']"></span>
          </p>
          <p class="sm-sub-title secondary">
            <span v-html="post['section2-p2']"></span>
          </p>
        </div>
        <div
          class="col-lg-3 my-sm-5 col-md-3 d-flex position-relative"
          v-touch:swipe="onSwipe"
        >
          <div v-scroll-ani class="scroll-ani top">
            <svg
              width="16"
              height="16"
              class="scroll-ani-circle"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="8"
                r="7"
                stroke="rgb(var(--text))"
                stroke-width="2"
              />
            </svg>

            <svg
              width="2"
              height="90"
              class="scroll-ani-line"
              viewBox="0 0 2 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="4.37114e-08"
                x2="0.999996"
                y2="100"
                stroke="rgb(var(--text))"
                stroke-width="2"
              />
            </svg>
          </div>
          <vueper-slides
            :dragging-distance="50"
            :touchable="false"
            class="w-100 dark-dots no-shadow facts-slide"
            :arrows="false"
            ref="dec"
            :autoplay="true"
          >
            <vueper-slide
              v-for="item in filterFacts($route.name)"
              :title="item.title"
              :key="item.title"
            >
              <template #content>
                <div class="vueperslide__content-wrapper">
                  <div class="ml-4 fact" :key="item.item">
                    <p class="title">
                      <span v-html="item.title"></span>
                    </p>
                    <p class="normal-text">
                      <span v-html="item._content"></span>
                    </p>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </div>

    <collaspe
      :text="post['section2-title']"
      :defaultImg="post['accordion-featured-image'].url"
    />
    <div
      class="col-lg-12 sm-hide decentralized-section-3 bg-padding"
      id="decentralized-section-3"
      v-if="!isSm"
      :style="`
      background:linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.8) 4.76%,
      rgba(0, 0, 0, 0) 62.14%
    ),
    url(${post['section3-bg'].url})`"
      v-toggle-header="{
        header: 'dark-header-decentralized-section-3',
        logo: 'light-logo-decentralized-section-3',
      }"
    >
      <div class="row bg-padding px-0">
        <div class="col-lg-6 col-md-6">
          <p class="secondary lg-title md-sub-title">
            <span v-html="post['section3-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 mb-md-5 pb-md-5 col-md-6">
          <p class="text-white sm-sub-title">
            <span v-html="post['section3-p1']"></span>
          </p>
          <p class="text-white sm-sub-title mt-4">
            <span v-html="post['section3-p2']"></span>
          </p>
          <div class="row mb-md-5 pb-md-5 mt-5 px-md-2">
            <div class="col-lg-3 col-md-3 px-md-2">
              <p class="text-white title">
                <span v-counter v-html="post['val-1']"></span>
              </p>
              <p class="normal-text text-white">
                <span v-html="post['val-1-des']"></span>
              </p>
            </div>
            <div class="col-lg-3 col-md-3 px-md-2">
              <p class="text-white title">
                <span v-counter v-html="post['val-2']"></span>
              </p>
              <p class="normal-text text-white">
                <span v-html="post['val-2-des']"></span>
              </p>
            </div>
            <div class="col-lg-3 col-md-3 px-md-2">
              <p class="text-white title">
                <span v-counter v-html="post['val-3']"></span>
              </p>
              <p class="normal-text text-white">
                <span v-html="post['val-3-des']"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 lg-hide md-hide mt-sm-5 bg-padding" v-if="isSm">
      <div class="secondary-border mt-0 mt-md-5 mb-1 top-border-primary"></div>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <p class="secondary lg-title md-sub-title">
            <span v-html="post['section3-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 mb-md-5 pb-md-5 mt-sm-3 mb-sm-3 col-md-6">
          <p class="sm-sub-title">
            <span v-html="post['section3-p1']"></span>
          </p>
          <p class="sm-sub-title mt-4">
            <span v-html="post['section3-p2']"></span>
          </p>
        </div>
      </div>
    </div>
    <div
      class="col-12 decentralized-section-3 mb-sm-5 lg-hide md-hide"
      id="decentralized-section-3_sm"
      :style="`
      background:linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.8) 4.76%,
      rgba(0, 0, 0, 0) 62.14%
    ),
    url(${post['section3-bg'].url})`"
      v-toggle-header="{
        header: 'dark-header-decentralized-section-3_sm',
        logo: 'light-logo-decentralized-section-3_sm',
      }"
      v-if="isSm"
    >
      <div class="row w-100 px-3">
        <div class="col-lg-3 col-md-3 col-sm-4 px-0">
          <p class="text-white title">
            <span v-counter v-html="post['val-1']"></span>
          </p>
          <p class="xs-text text-white">
            <span v-html="post['val-1-des']"></span>
          </p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 px-0">
          <p class="text-white title">
            <span v-counter v-html="post['val-2']"></span>
          </p>
          <p class="xs-text text-white">
            <span v-html="post['val-2-des']"></span>
          </p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 px-0">
          <p class="text-white title">
            <span v-counter v-html="post['val-3']"></span>
          </p>
          <p class="xs-text text-white">
            <span v-html="post['val-3-des']"></span>
          </p>
        </div>
      </div>
    </div>
    <testiSlide page="decentralised-renewable-solutions" />
    <recentBusiness tag="Decentralised Renewable Solutions" />
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
import recentBusiness from "../common/recentBusiness.vue";
import collaspe from "../common/collaspe.vue";
import testiSlide from "../common/testiSlide.vue";
import Footer from "../common/footer.vue";
import { mapGetters } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";

import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Decentralised Renewable Solutions",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  computed: {
    ...mapGetters(["isSm", "isLg", "filterFacts"]),
  },
  data() {
    return {
      post: {},
      getting: true,
    };
  },
  components: {
    recentBusiness,
    collaspe,
    testiSlide,
    Footer,
    VueperSlides,
    VueperSlide,
  },
  mounted() {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("decentralised");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("decentralised").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.dec.previous();
      } else if (direction === "left") {
        this.$refs.dec.next();
      }
    },
  },
};
</script>

<style scoped>
.decentralized-img img {
  max-height: 347px;
  object-fit: cover;
}
.decentralized-section-3 {
  background-position: 95% 20% !important;
  background-repeat: no-repeat !important;
  background-size: 115% !important;
}
</style>