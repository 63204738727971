<template>
  <div class="row" v-if="!getting">
    <div class="header-height"></div>
    <div class="col-lg-12 bg-padding" v-if="isReady">
      <div class="row">
        <div class="col-lg-12">
          <p class="lg-title secondary">Business Intelligence</p>
        </div>
        <div class="col-12">
          <div class="top-border-primary"></div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 mt-md-3">
          <p class="normal-text mb-2">
            Want to receive our business intelligence directly?
          </p>
          <p class="normal-text mb-3">
            <router-link
              to="/contact-us"
              class="secondary normal-text underline-text"
            >
              SIGN UP TO OUR MAILING LIST
            </router-link>
          </p>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 mt-md-3">
          <div class="flex-align-center md-hide flex-lg-end sm-hide">
            <v-select
              hide-selected
              v-model="tag"
              class="mr-5"
              close-on-select
              clear-on-close
              clearable
              placeholder="Filter by Subject"
              :options="subjects"
              label-by="name"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text" v-html="option.name"></span>
                </slot>
              </template>
            </v-select>
            <v-select
              v-model="tag"
              close-on-select
              label-by="name"
              placeholder="Filter by Year"
              :options="years"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text" v-html="option.name"></span>
                </slot> </template
            ></v-select>
            <button
              @click="tag = {}"
              v-if="$route.query.tag"
              class="input ml-5 secondary-bg text-white normal-text"
            >
              <p>Clear filter</p>
            </button>
          </div>
          <!-- <div class="flex-align-center lg-hide sm-hide">
            <v-select
              hide-selected
              v-model="tag"
              class="mr-5"
              close-on-select
              clear-on-close
              clearable
              placeholder="Filter by Subject"
              :options="subjects"
              label-by="name"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text" v-html="option.name"></span>
                </slot>
              </template>
            </v-select>
            <v-select
              v-model="tag"
              hide-selected
              close-on-select
              label-by="name"
              placeholder="Filter by Year"
              :options="years"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text" v-html="option.name"></span>
                </slot> </template
            ></v-select>
            <button
              @click="tag = {}"
              v-if="$route.query.tag"
              class="input ml-5 secondary-bg text-white normal-text"
            >
              <p>Clear filter</p>
            </button>
          </div> -->
          <!-- <div class="lg-hide md-hide mt-3">
            <v-select
              hide-selected
              class="w-100 mb-3"
              v-model="tag"
              close-on-select
              clear-on-close
              clearable
              placeholder="Filter by Subject"
              :options="subjects"
              label-by="name"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text" v-html="option.name"></span>
                </slot>
              </template>
            </v-select>
            <v-select
              v-model="tag"
              hide-selected
              close-on-select
              class="w-100 mb-3"
              label-by="name"
              placeholder="Filter by Year"
              :options="years"
            >
              <template #dropdown-item="{ option }">
                <slot name="dropdown-item" :option="option.originalOption">
                  <span class="normal-text">{{ option.label }}</span>
                </slot>
              </template></v-select
            >
            <button
              @click="tag = {}"
              v-if="$route.query.tag"
              class="input w-100 secondary-bg text-white normal-text px-4"
            >
              <p>Clear filter</p>
            </button>
          </div> -->
        </div>
      </div>
      <businessIntelPost hideBorder :type="reports" />
      <businessIntelPost :type="tech" />
      <businessIntelPost :type="artical" />
      <businessIntelPost :type="myanmar" />
    </div>
    <div class="col-lg-12 bg-padding" v-if="!isReady">
      <p class="lg-title">Business Intelligence</p>
      <div class="top-border-primary bg-m mx-0 mb-0 mb-2"></div>

      <div class="row">
        <div class="col-lg-12 mb-4">
          <img
            src="/image/loader.svg"
            style="width: 20%; height: 60px"
            class=""
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-6 loader-box mb-4" v-for="i in 8" :key="i">
          <img
            src="/image/loader.svg"
            style="height: 150px"
            class="w-100"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
import businessIntelPost from "../common/businessIntelPost.vue";
import { mapState } from "vuex";
import Footer from "../common/footer.vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Business Intelligence",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      post: {},
      getting: true,
      tag: {},
    };
  },
  watch: {
    isReady(value) {
      if (value) {
        const url = new URL(window.location);
        if (url.hash) {
          this.$store.commit("scrollToHash", url);
        }
      }
    },
    years() {
      this.filterTag();
    },
    subjects() {
      this.filterTag();
    },
    tag(val) {
      if (val.slug !== this.$route.query.tag) {
        this.$router.push({
          name: "Business Intelligence",
          query: {
            tag: val.slug,
          },
        });
      }
      if (val.name) {
        const newName = {};
        Object.defineProperty(newName, "name", {
          get() {
            return val.name;
          },
          set(newValue) {
            val.name = newValue;
          },
        });
        newName.name = String(val.name).replace("&amp;", "&");
      }
    },
  },
  mounted() {
    if (this.isReady) {
      const url = new URL(window.location);
      if (url.hash) {
        this.$store.commit("scrollToHash", url);
      }

      this.filterTag();
    }
  },
  components: {
    businessIntelPost,
    Footer,
  },
  computed: {
    ...mapState([
      "isReady",
      "reports",
      "tech",
      "artical",
      "myanmar",
      "subjects",
    ]),
    years: () => {
      const currentYear = new Date().getFullYear();
      var years_ = [];

      for (var i = 0; i < 8; i++) {
        years_.push({
          name: currentYear - i,
          slug: currentYear - i,
        });
      }

      return years_;
    },
  },

  created() {
    this.getData();
  },
  methods: {
    filterTag() {
      if (this.$route.query.tag) {
        const filteredYears = this.years.filter(
          (data) => data.slug == this.$route.query.tag
        );
        if (filteredYears.length > 0) {
          this.tag = filteredYears[0];
        } else {
          const filteredSubjects = this.subjects.filter(
            (data) => data.slug == this.$route.query.tag
          );
          this.tag = filteredSubjects[0];
        }
      }
    },
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("business-intelligenc");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("business-intelligenc").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
  },
};
</script>

<style >
.icon.delete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
  max-height: 8px;
  max-width: 8px;
  cursor: pointer;
}

.icon.arrow-downward {
  width: 10px !important;
  height: 9px !important;
  border-style: solid;
  transition: transform 0.2s linear;
  order: -1;
  border: 1px solid rgb(var(--primary));
  border-width: 0px 1px 1px 0;
  cursor: pointer;
  transform: rotate(45deg);
}

.icon.arrow-downward.active {
  transform: rotate(225deg);
}

.vue-select {
  position: relative;
  display: flex;
  align-items: center;
  width: 218px;
  height: 40px;
  border: 1px solid rgb(var(--primary));
  box-sizing: border-box;
  outline: none;
}

.vue-select[aria-disabled="true"] {
  background-color: rgba(239, 239, 239);
}

.vue-select[aria-disabled="true"] * {
  cursor: not-allowed;
}

.vue-select[aria-disabled="true"] input {
  cursor: not-allowed;
}

.vue-select-header {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.vue-select-header .icon.loading,
.vue-select-header .icon.arrow-downward {
  margin-left: 15px;
  margin-right: 25px;
}

.vue-select-input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.vue-input {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border: none;
  outline: none;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
}

.vue-select[data-is-focusing="false"][aria-disabled="false"] .vue-input input,
input[readonly] {
  cursor: default;
}

.vue-input input {
  height: 100%;
  border: none;
  outline: none;
  width: 100%;
  min-width: 0;
  font-size: 0.8rem;
  padding: 0;
}

.vue-input input::placeholder {
  color: rgb(var(--primary));
  opacity: 1; /* Firefox */
  font-size: 14px;
}

.vue-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(var(--primary));
  font-size: 14px;
}

.vue-input input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(var(--primary));
  font-size: 14px;
}

.vue-input input[disabled] {
  background-color: rgba(239, 239, 239);
}

.vue-input input[readonly] {
  background-color: unset;
}

.vue-select-header .vue-input input[disabled] {
  background-color: unset;
}

.vue-dropdown {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 1;
  overflow-y: auto;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  left: -1px;
  box-sizing: content-box;
  border: 1px solid rgb(var(--primary));
  list-style-type: none;
}

.vue-select[aria-expanded="true"] .vue-dropdown {
  display: unset;
}

.vue-dropdown[data-visible-length="0"] {
  border: none;
}

.vue-dropdown-item {
  list-style-type: none;
  padding: 4px;
  cursor: pointer;
  min-height: 1rem;
}

.vue-dropdown-item.highlighted {
  background-color: rgb(var(--secondary));
  color: #fff;
}

.vue-dropdown-item.disabled {
  background-color: rgba(239, 239, 239);
  cursor: not-allowed;
}

.vue-dropdown-item.selected {
  background-color: #f3f3f3;
}

.vue-dropdown-item.selected.highlighted {
  background-color: #ff6a6a;
}

.vue-dropdown[data-removable="false"] .vue-dropdown-item.selected:hover {
  cursor: not-allowed;
}

.vue-dropdown[data-addable="false"][data-multiple="true"]
  .vue-dropdown-item:not(.selected):hover {
  cursor: not-allowed;
}

.inline-flex {
  display: inline-flex;
}

.vue-select[aria-expanded="true"].direction-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vue-select[aria-expanded="true"].direction-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vue-select.direction-top .vue-dropdown {
  bottom: 100%;
}

.vue-select.direction-bottom .vue-dropdown {
  top: 100%;
}
</style>