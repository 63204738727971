const isLoggedInCookie = document.cookie
  .split("; ")
  .find((row) => row.startsWith("isLoggedIn="));
if (isLoggedInCookie) {
  var isLoggedIn = isLoggedInCookie.split("=")[1];
}
const userCookie = document.cookie
  .split("; ")
  .find((row) => row.startsWith("user="));
if (userCookie) {
  var user = userCookie.split("=")[1];
}
export default {
  user,
  isLoggedIn,
  loading: false,
  isReady: false,
  homeHeader: {
    english: "",
    myanmar: "",
  },
  gotData: localStorage.getItem("gotData"),
  facts: [],
  subjects: [
    {
      name: "",
      term_id: "",
    },
  ],
  years: [
    {
      name: "",
      term_id: "",
    },
  ],
  accordion: [],
  reports: {},
  tech: {},
  artical: {},
  myanmar: {},
  facebook: {},
};
