<template>
  <div
    class="row"
    style="margin-left: -11px; margin-right: -11px; justify-content: center"
    v-if="!getting"
  >
    <div
      class="col-lg-2 px-sm-1 col-md-3 animated fadeIn"
      style="
        padding: 0px 11px !important;
        animation-delay: 300ms;
        margin-bottom: 22px;
      "
    >
      <div class="member-title">
        <p class="lg-title primary mb-sm-5 text-bold member-section-title">
          <span v-html="title"></span>
        </p>
        <p class="normal-text mt-3 member-section-text">
          <span v-html="des"></span>
        </p>
      </div>
    </div>
    <div
      v-for="(member, index) in members"
      class="col-lg-2 member-box- col-md-3 animated fadeIn px-sm-2 col-sm-6"
      :style="`animation-delay:${50 * index}ms;`"
      :key="member.slug"
      @click="toggle(index)"
    >
      <div class="member-box card-hidden-box">
        <img class="w-100" v-lazy="member['member-image'].url" alt="" />
        <div
          class="member-box-content card-hidden"
          :class="activeIndex == index && 'show'"
          v-cardHidden="47"
        >
          <p class="normal-text member-title-text pb-2 text-white">
            {{ member.title.rendered }}
          </p>
          <p class="sm-text mt-sm-1 mb-2 text-white">
            <span v-html="member['menber-title']"></span>
          </p>
          <a :href="member['social-link']" class="mb-2" target="_blank">
            <svg
              width="28"
              height="27"
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8033 0C6.46584 0 0.515625 5.95126 0.515625 13.29C0.515625 20.6287 6.46584 26.58 13.8033 26.58C21.1408 26.58 27.091 20.6287 27.091 13.29C27.091 5.95126 21.1408 0 13.8033 0ZM9.94205 20.0907H6.7059V10.353H9.94205V20.0907ZM8.32408 9.02331H8.30299C7.21704 9.02331 6.5147 8.27563 6.5147 7.34118C6.5147 6.38563 7.23853 5.65863 8.34557 5.65863C9.45261 5.65863 10.1339 6.38563 10.1549 7.34118C10.1549 8.27563 9.45261 9.02331 8.32408 9.02331ZM21.6109 20.0907H18.3752V14.8813C18.3752 13.5721 17.9066 12.6792 16.7357 12.6792C15.8418 12.6792 15.3094 13.2815 15.0754 13.8629C14.9898 14.0709 14.9689 14.3617 14.9689 14.6527V20.0907H11.733C11.733 20.0907 11.7754 11.2666 11.733 10.353H14.9689V11.7318C15.399 11.0682 16.1684 10.1245 17.8853 10.1245C20.0145 10.1245 21.6109 11.5162 21.6109 14.5071V20.0907Z"
                fill="#EFEEEE"
              />
            </svg>
          </a>
        </div>
        <!-- 
        <div
          class="member-box-content-sm lg-hide md-hide"
          :class="activeIndex == index && 'show'"
        >
          <p class="normal-text member-title-text pb-2 text-white">
            {{ member.title.rendered }}
          </p>
          <p class="sm-text mb-2 text-white">
            <span v-html="member['menber-title']"></span>
          </p>
          <a :href="member['social-link']" class="mb-2" target="_blank">
            <svg
              width="28"
              height="27"
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8033 0C6.46584 0 0.515625 5.95126 0.515625 13.29C0.515625 20.6287 6.46584 26.58 13.8033 26.58C21.1408 26.58 27.091 20.6287 27.091 13.29C27.091 5.95126 21.1408 0 13.8033 0ZM9.94205 20.0907H6.7059V10.353H9.94205V20.0907ZM8.32408 9.02331H8.30299C7.21704 9.02331 6.5147 8.27563 6.5147 7.34118C6.5147 6.38563 7.23853 5.65863 8.34557 5.65863C9.45261 5.65863 10.1339 6.38563 10.1549 7.34118C10.1549 8.27563 9.45261 9.02331 8.32408 9.02331ZM21.6109 20.0907H18.3752V14.8813C18.3752 13.5721 17.9066 12.6792 16.7357 12.6792C15.8418 12.6792 15.3094 13.2815 15.0754 13.8629C14.9898 14.0709 14.9689 14.3617 14.9689 14.6527V20.0907H11.733C11.733 20.0907 11.7754 11.2666 11.733 10.353H14.9689V11.7318C15.399 11.0682 16.1684 10.1245 17.8853 10.1245C20.0145 10.1245 21.6109 11.5162 21.6109 14.5071V20.0907Z"
                fill="#EFEEEE"
              />
            </svg>
          </a>
        </div> -->
      </div>
    </div>
  </div>

  <div
    v-else
    class="row"
    style="margin-left: -11px; margin-right: -11px; justify-content: center"
  >
    <div
      v-for="index in 16"
      class="col-lg-2 member-box- col-md-3 px-sm-2 col-sm-6 animated fadeIn"
      :key="index"
      :style="`animation-delay:${50 * index}ms;`"
    >
      <div class="member-box">
        <img class="w-100 h-100" src="image/loader.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueScreenSizeMixin } from 'vue-screen-size';
export default {
  mixins: [VueScreenSizeMixin],
  props: {
    title: {
      type: String,
      default: "",
    },
    des: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // windowWidth : document.documentElement.clientWidth,
      // windowHeight : document.documentElement.clientHeight,
      members: [],
      getting: true,
      activeIndex: null,
      
    };
  },
  mounted() {
    // window.addEventListener('resize', this.setDimensions);
    this.getMembers();
    
  },
  methods: {
    // setDimensions() {
    //     this.windowWidth = document.documentElement.clientWidth;
    //     this.windowHeight = document.documentElement.clientHeight;
    // },
    // beforeDestroy() {
    // window.removeEventListener('resize', this.setDimensions);
    // },
    getMembers() {
      this.getting = true;

      const cacheData = this.$get.getCachedData("team?per_page=100");
      if (cacheData) {
        var members = cacheData;
        members.sort(function (a, b) {
          if (a.title.rendered > b.title.rendered) {
            return -1;
          }
          if (a.title.rendered < b.title.rendered) {
            return 1;
          }
          return 0;
        });
        this.members = members;
        this.members.forEach((member) => {
          this.$store.commit("preloadImage", member["member-image"].url);
        });
        this.getting = false;
      }
      this.$get.getFreshData("team?per_page=100").then((data) => {
        var members = data;
        members.sort(function (a, b) {
          if (a.title.rendered > b.title.rendered) {
            return -1;
          }
          if (a.title.rendered < b.title.rendered) {
            return 1;
          }
          return 0;
        });
        this.members = members;
        this.members.forEach((member) => {
          this.$store.commit("preloadImage", member["member-image"].url);
        });
        this.getting = false;
      });
    },
    toggle(index) {
      if (this.activeIndex == index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
  },
};
</script>

<style scoped>
.member-title-text::first-letter {
  color: rgb(var(--secondary)) !important;
}
.member-title p.normal-text {
  margin: 0px !important;
  position: absolute;
  bottom: 10px;
  right: 15px;
  left: 15px;
}
.member-title {
  padding: 15px;
  background: rgb(var(--dark), 0.15);
  height: 288px;
  position: relative;
}
.member-box- {
  padding: 0px 11px;
  margin-bottom: 22px;
}
.member-box {
  position: relative;
  background: rgb(var(--primary));
  height: 288px;
  overflow: hidden;
}

.member-box-content {
  min-height: 100px;
  background: rgb(var(--primary));
  z-index: 1;
  width: 100%;
  padding: 15px;
  height: 146px;
}
.member-box img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.member-box img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: top;
}
</style>