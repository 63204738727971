<template>
  <div class="main-header">
    <div
      class="row mx-0 lower-header"
      v-show="$route.name !== 'Single Map'"
      :class="headerClass"
    >
      <div class="col-12 w-100 border header-border"></div>
      <div class="col-lg-3 col-md-3"></div>
      <div class="col-lg-9 col-md-9 menu-items">
        <router-link
          to="/who-we-are"
          :class="activeName == 'who-we-are-page' && 'exact-active'"
          class="normal-text underline-btn-gp sm-hide"
        >
          <p class="underline-btn-child">Who We Are</p></router-link
        >
        <router-link
          to="/innovative-finance"
          :class="activeName == 'innovation-finance-page' && 'exact-active'"
          class="normal-text underline-btn-gp sm-hide"
          ><p class="underline-btn-child">Innovative</p>
          <p class="underline-btn-child">Finance</p></router-link
        >
        <router-link
          to="/decentralised-renewable-solutions"
          :class="
            activeName == 'decentralised-renewable-solutions-page' &&
            'exact-active'
          "
          class="normal-text underline-btn-gp sm-hide"
        >
          <p class="underline-btn-child">Decentralised</p>
          <p class="underline-btn-child">Renewable Solutions</p>
        </router-link>
        <router-link
          to="/integrated-electrification"
          :class="
            activeName == 'integrated-electrification-page' && 'exact-active'
          "
          class="normal-text underline-btn-gp sm-hide"
        >
          <p class="underline-btn-child">Integrated</p>
          <p class="underline-btn-child">Electrification</p>
        </router-link>
        <router-link
          to="/business-intelligence"
          :class="activeName == 'business-intelligence-page' && 'exact-active'"
          class="normal-text underline-btn-gp sm-hide"
        >
          <p class="underline-btn-child">Business</p>
          <p class="underline-btn-child">Intelligence</p>
        </router-link>
        <router-link
          to="/contact-us"
          :class="activeName == 'contact-us-page' && 'exact-active'"
          class="normal-text underline-btn-gp sm-hide"
        >
          <p class="underline-btn-child">Contact Us</p>
        </router-link>
        <div class="menu" :class="openBurger && 'opened'" @click="openMenu">
          <div :class="open && 'opened'" class="menu-bg"></div>

          <Menu v-show="showMenu" class="animated fadeIn" />

          <svg
            width="100"
            height="100"
            style="transform: scale(0.7)"
            viewBox="0 0 100 100"
          >
            <path
              class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path class="line line2" d="M 20,50 H 80" />
            <path
              class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="row" v-if="activeName == 'home-page'">
      <div class="upper-header col-12">
        <img
          v-if="homeHeader.background"
          :src="homeHeader.background.url"
          alt=""
          class="header-bg"
        />
        <div class="filter-bg lg-hide md-hide sm-hide"></div>
        <div class="row" style="height: 100%; align-items: center">
          <div class="col-lg-4 col-md-4"></div>
          <div class="col-lg-7 px-sm-3 col-md-6 header-content">
            <!-- toggle replace area -->
            <Toggle
              v-model="isMM"
              style="margin-top: 10px"
              :onLabel="'MM'"
              :offLabel="'EN'"
            />

            <div
              class="w-100 position-relative dy-height"
              :style="'height: 300px'"
            >
              <transition name="fade">
                <p
                  class="
                    text-white
                    sub-title
                    md-sm-sub-title
                    position-absolute
                    my-0
                    w-100
                    lang-text
                  "
                  v-if="!isMM"
                  style="line-height: 1.7 !important"
                >
                  <span
                    class="text-heightlight"
                    v-html="homeHeader.english"
                  ></span>
                </p>
                <p
                  class="
                    text-white
                    position-absolute
                    sub-title
                    md-sm-sub-title
                    my-0
                    lang-text
                  "
                  v-else
                  style="line-height: 1.7 !important"
                >
                  <span class="padauk text-heightlight">
                    Smart Power Myanmar တွင် ရည်မှန်းချက်တစ်ရပ်ရှိပြီး
                    ရင်းနှီးမြှုပ်နှံမှုအသစ်များ၊ အသိပညာ နည်းပညာအသစ်များ
                    ပေါင်းစပ်ပံ့ပိုးခြင်းဖြင့် လျှပ်စစ်ကဏ္ဍကို
                    အရှိန်အဟုန်မြှင့်တင်ပြီး နိုင်ငံတော်၏ ဓာတ်အားလိုအပ်မှုကို
                    အဆုံးသတ်ကာ စီးပွားရေးအခွင့်အလမ်းများ တိုးတက်လာစေရန်
                    ရည်ရွယ်သည်။
                  </span>
                </p>
              </transition>
            </div>
            <!-- toggle orginal area -->
          </div>
        </div>

        <div id="header-down" class="scroll-down">
          <a href="#home-section-2"><span></span>Scroll</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
		id="low-header"
		class="row mx-0 lower-header"
		v-show="$route.name !== 'Single Map'"
		:class="headerClass"
	>
		<div class="col-12 w-100 border header-border"></div>
		<div class="col-lg-3 col-md-3"></div>
		<div class="col-lg-9 col-md-9 menu-items">
			<router-link
				to="/who-we-are"
				:class="activeName == 'who-we-are-page' && 'exact-active'"
				class="normal-text underline-btn-gp sm-hide"
			>
				<p class="underline-btn-child">Who We Are</p></router-link
			>
			<router-link
				to="/innovative-finance"
				:class="activeName == 'innovation-finance-page' && 'exact-active'"
				class="normal-text underline-btn-gp sm-hide"
				><p class="underline-btn-child">Innovative</p>
				<p class="underline-btn-child">Finance</p></router-link
			>
			<router-link
				to="/decentralised-renewable-solutions"
				:class="
					activeName == 'decentralised-renewable-solutions-page' &&
						'exact-active'
				"
				class="normal-text underline-btn-gp sm-hide"
			>
				<p class="underline-btn-child">Decentralised</p>
				<p class="underline-btn-child">Renewable Solutions</p>
			</router-link>
			<router-link
				to="/integrated-electrification"
				:class="
					activeName == 'integrated-electrification-page' && 'exact-active'
				"
				class="normal-text underline-btn-gp sm-hide"
			>
				<p class="underline-btn-child">Integrated</p>
				<p class="underline-btn-child">Electrification</p>
			</router-link>
			<router-link
				to="/business-intelligence"
				:class="activeName == 'business-intelligence-page' && 'exact-active'"
				class="normal-text underline-btn-gp sm-hide"
			>
				<p class="underline-btn-child">Business</p>
				<p class="underline-btn-child">Intelligence</p>
			</router-link>
			<router-link
				to="/contact-us"
				:class="activeName == 'contact-us-page' && 'exact-active'"
				class="normal-text underline-btn-gp sm-hide"
			>
				<p class="underline-btn-child">Contact Us</p>
			</router-link>
			<div class="menu" :class="open && 'opened'" @click="openMenu">
				<div class="menu-bg"></div>

				<Menu v-show="showMenu" class="animated fadeIn" />

				<svg
					width="100"
					height="100"
					style="transform: scale(0.7)"
					viewBox="0 0 100 100"
				>
					<path
						class="line line1"
						d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
					/>
					<path class="line line2" d="M 20,50 H 80" />
					<path
						class="line line3"
						d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
					/>
				</svg>
			</div>
		</div>
	</div> -->
  <transition
    name="fade"
    v-show="$route.name !== 'Single Map'"
    style="animation-delay: 600ms"
  >
    <router-link
      v-show="!open"
      to="/"
      class="site-logo"
      :class="activeName !== 'page' && 'sm-logo'"
    >
      <logo />
    </router-link>
  </transition>
</template>

<script>
import Toggle from "@vueform/toggle";
import Menu from "./menu.vue";
import logo from "./logo.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isMM: false,
      showMenu: false,
      siteLogo: "/image/light-logo.svg",
      offsetTop: 0,
      logo: null,
      open: false,
      activeName: "home-page",
      clientHeight: 0,
      percent: 0,
      headerClass: "",
      headerBorder: null,
      dyHeight: 0,
      openBurger: false,
    };
  },
  watch: {
    $route(next) {
      // window.scrollTo(0, 0);
      this.activeName = next.meta.class;
      if (
        this.activeName == "who-we-are-page" ||
        this.activeName == "home-page"
      ) {
        this.siteLogo = "/image/light-logo.svg";
      } else {
        this.siteLogo = "/image/dark-logo.png";
      }
      if (this.activeName == "home-page") {
        this.headerClass = "home-header";
        this.logo = document.querySelector(".site-logo");
        this.headerBorder = document.querySelector(".header-border");
        const lang = document.getElementsByClassName("lang-text");
        if (lang[0]) {
          this.dyHeight = lang[0].clientHeight + "px !important";
        }
      } else if (this.activeName == "who-we-are-page") {
        this.headerClass = "who-we-are-header";
      } else {
        this.headerClass = "";
      }
    },
    showMenu(val) {
      if (val) {
        setTimeout(() => {
          this.setHeight();
        }, 300);
      }
    },

    isMM() {
      setTimeout(() => {
        const lang = document.getElementsByClassName("lang-text");
        this.dyHeight = lang[0].clientHeight + "px !important";
      }, 350);
    },

    homeHeader() {
      setTimeout(() => {
        const lang = document.getElementsByClassName("lang-text");
        this.dyHeight = lang[0].clientHeight + "px !important";
      }, 150);
    },
  },
  components: {
    Toggle,
    Menu,
    logo,
  },
  computed: {
    ...mapState(["homeHeader"]),
  },
  created() {
    window.addEventListener("scroll", this.onPageScroll);
  },
  mounted() {
    this.activeName = this.$route.meta.class;
    if (this.activeName == "home-page") {
      this.headerClass = "home-header";
      this.logo = document.querySelector(".site-logo");
      this.headerBorder = document.querySelector(".header-border");
      const lang = document.getElementsByClassName("lang-text");
      this.dyHeight = lang[0].clientHeight + "px !important";
    } else if (this.activeName == "who-we-are-page") {
      this.headerClass = "who-we-are-header";
    } else {
      this.headerClass = "";
    }

    setTimeout(() => {
      const lang = document.getElementsByClassName("lang-text");
      if (lang[0]) {
        this.dyHeight = lang[0].clientHeight + "px !important";
      }
    }, 150);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onPageScroll);
  },
  methods: {
    scroll() {
      const element = document.getElementById("contact-me");
      element.scrollIntoView({ behavior: "smooth" });
    },

    setHeight() {
      var height = 0;
      const elements = document.querySelectorAll(".menu-title-header");
      elements.forEach((element) => {
        if (height < element.clientHeight) {
          height = element.clientHeight;
        }
      });

      elements.forEach((element) => {
        element.setAttribute(
          "style",
          `height: ${height}px !important;align-items:flex-start !important;`
        );
      });
    },
    onPageScroll(e) {
      const header = document.querySelector(".lower-header");

      if (this.activeName == "home-page") {
        if (e.target.scrollingElement.scrollTop > header.offsetTop - 45) {
          this.logo.classList.add("sm-logo");
          this.headerBorder.classList.add("show");
        } else if (e.target.scrollingElement.scrollTop < header.offsetTop) {
          this.logo.classList.remove("sm-logo");
          this.headerBorder.classList.remove("show");
        }
      }
    },
    openMenu() {
      const header = document.querySelector(".lower-header");
      if (header.offsetTop > 0) {
        window.scrollTo(0, header.offsetTop);
      }
      if (!this.open) {
        this.open = true;
        this.openBurger = true;
        setTimeout(() => {
          this.showMenu = true;
        }, 300);
      } else {
        this.showMenu = false;
        this.open = false;
        setTimeout(() => {
          this.openBurger = false;
        }, 550);
      }
    },
  },
};
</script>

<style scoped>
.menu-bg {
  width: 0.1rem;
  height: 0.1rem;
  background: rgba(var(--primary), 1);
  position: absolute;
  border-radius: 100%;
  transform: scale(1);
  transition: 0.65s all ease;
}
.opened.menu-bg {
  /* background: rgba(var(--primary), 1); */
  transform: scale(3500) !important;
}
.text-heightlight {
  background: #0d2e4a44;
  box-shadow: -3px 3px 10px 5px #0d2e4a44;
}
.lower-header.who-we-are-header {
  position: fixed;
}
.lower-header.home-header {
  position: fixed;
  background: none !important;
  backdrop-filter: blur(3px);
}
.filter-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: rotate(-180deg);
  /* background: linear-gradient(-180deg, #0d2e4a 0%, rgba(13, 46, 74, 0) 90.3%); */
  background: linear-gradient(-180deg, #0d2e4a -10%, rgba(13, 46, 74, 0) 70%);
}
.menu-items .exact-active {
  color: rgb(var(--secondary)) !important;
}
.dy-height {
  transition: 0.3s all ease-in-out;
}
.hide {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.show {
  opacity: 1;
}
.site-logo {
  position: fixed;
  top: var(--bg-padding);
  left: var(--bg-padding);
  z-index: 10000;
  width: 216px;
  transition: 0.45s all cubic-bezier(0.42, 0, 0.58, 1);
}

.site-logo.sm-logo {
  top: 30px;
  left: var(--bg-padding);
  width: 160px !important;
}
.upper-header {
  padding: var(--bg-padding) var(--bg-padding)
    calc(var(--bg-padding) - var(--sm-padding));
  background: rgb(var(--primary));
  z-index: 1000;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.header-content {
  display: flex;
  flex-direction: column;
  /* height: calc(285px - var(--bg-padding)); */
  justify-content: space-between;
  padding: calc(var(--bg-padding) + 2.5rem) calc(var(--bg-padding) + 10px) 0px
    calc(var(--bg-padding) + 35px);
}
.solid-header .lower-header {
  background: rgba(13, 46, 74, 1) !important;
}
.lower-header {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 15px;
  right: 15px;
  margin-top: -1px;
  padding: 1rem var(--bg-padding) 0.5rem;
  background: rgba(13, 46, 74, 0.003);
  backdrop-filter: blur(10px);
  color: rgb(var(--primary));
  /* transition: 0.3s all ease-in-out; */
  margin: 0px -15px !important;
}
/* .home-page .lower-header {
		position: sticky !important;
		background: rgba(var(--white));
		
		color: rgb(var(--primary)) !important;
	} */
.who-we-are-page .lower-header {
  position: fixed !important;
  background: rgba(13, 46, 74, 0.5);
  /* backdrop-filter: unset !important; */
}
[class*="light-header-"] .lower-header {
  background: rgba(239, 238, 238, 0.5) !important;
  /* backdrop-filter: unset !important; */
}
[class*="dark-header-"] .lower-header {
  background: rgba(13, 46, 74, 0.5) !important;
  /* backdrop-filter: unset !important; */
}
.who-we-are-page .lower-header a,
.home-page .lower-header a {
  color: rgb(var(--white));
}
.lower-header a,
[class*="light-header-"] .lower-header a {
  color: rgb(var(--text));
}
[class*="dark-header-"] .lower-header a {
  color: rgb(var(--white)) !important;
}
.who-we-are-page .lower-header .border {
  border-top: 1px solid rgb(var(--border));
}

.lower-header .border,
[class*="light-header-"] .lower-header .border {
  border-top: 1px solid rgb(var(--primary));
}

[class*="dark-header-"] .lower-header .border {
  border-top: 1px solid rgb(var(--border)) !important;
}
a.router-link-active {
  display: inline-flex;
  flex-direction: column;
  text-decoration: none !important;
}
.who-we-are-page .lower-header .menu,
.home-page .lower-header .menu {
  box-shadow: 0 0 0 0 rgb(var(--primary)), 0 0 0 0 rgb(var(--primary));
}
[class*="light-header-"] .lower-header .menu {
  box-shadow: 0 0 0 0 rgb(var(--white)), 0 0 0 0 rgb(var(--white));
}
[class*="dark-header-"] .lower-header .menu {
  box-shadow: 0 0 0 0 rgb(var(--primary)), 0 0 0 0 rgb(var(--primary));
}
.who-we-are-page .lower-header .line,
.home-page .lower-header .line {
  stroke: rgb(var(--white));
}
[class*="light-header-"] .lower-header .line {
  stroke: rgb(var(--primary)) !important ;
}
[class*="dark-header-"] .lower-header .line {
  stroke: rgb(var(--white)) !important ;
}
.menu-items {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.menu {
  width: 2.8rem;
  height: 2.8rem;
  box-shadow: 0 0 0 0 rgb(var(--white)), 0 0 0 0 rgb(var(--white));
  border-radius: 100%;
  transition: 0.45s all ease-in-out;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
}
.lower-header.home-header .menu.opened {
  /* box-shadow: 0 0 0 150vw rgb(var(--primary)), 0 0 0 150vw rgb(var(--primary)) !important; */
  background: rgb(var(--primary)) !important;
}
.lower-header.home-header .menu.opened .line {
  stroke: rgb(var(--white)) !important ;
}

.lower-header.who-we-are-header .menu.opened,
.menu.opened {
  /* box-shadow: 0 0 0 150vw rgb(var(--primary)), 0 0 0 150vw rgb(var(--primary)) !important; */
  background: rgb(var(--primary)) !important;
}
.lower-header.who-we-are-header .menu.opened .line,
.opened .line {
  stroke: rgb(var(--white)) !important ;
}

.line {
  fill: none;
  stroke: rgb(var(--primary));
  stroke-width: 6;
  transition: stroke-dasharray 0.45s cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 0.45s cubic-bezier(0.4, 0, 0.2, 1),
    stroke 0.45s cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.scroll-down a {
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
.scroll-down a:hover {
  opacity: 0.5;
}

#header-down a {
  padding-top: 60px;
}
/* #header-down a span {
		position: absolute;
		top: 0;
		left: 50%;
		width: 30px;
		height: 50px;
		margin-left: -15px;
		border: 2px solid #fff;
		border-radius: 50px;
		box-sizing: border-box;
	} */
#header-down a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
/* section {
		position: relative;
		width: 100%;
		height: 100%;
	}
	section::after {
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
		width: 100%;
		height: 80%;
		background: -webkit-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0,
			rgba(0, 0, 0, 0.8) 80%,
			rgba(0, 0, 0, 0.8) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0) 0,
			rgba(0, 0, 0, 0.8) 80%,
			rgba(0, 0, 0, 0.8) 100%
		);
	} */
/* section h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		color: #fff;
		font: normal 300 64px/1 'Josefin Sans', sans-serif;
	} */
/* ------------------------------------------------'mobile'------------------------------------------------ */
@media screen and (max-width: 767px) {
  img.header-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 100%;
    bottom: 0px;
    object-fit: cover;
    object-position: center;
  }
  .header-content {
    margin-top: 180px;
    /* height: 220px;  */
    margin-bottom: 105px;
  }
  .site-logo {
    position: fixed;
    top: calc(var(--bg-padding) + 30px);
    left: var(--bg-padding);
    z-index: 10000;
    width: 195px;
  }
  .site-logo.sm-logo {
    top: 27px;
    width: 111px !important;
  }
  .menu-items {
    justify-content: flex-end;
  }
  .home-page .menu-items {
    padding-right: 0px !important;
  }
  .scroll-down a {
    left: 41% !important;
  }
}
/* ------------------------------------------------'desktop'------------------------------------------------ */
@media (min-width: 1200px) {
  .upper-header {
    height: 100vh;
  }
  .header-content p.text-white.sub-title {
    font-size: 1.3rem !important;
  }
  img.header-bg {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0px;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
/* ------------------------------------------------'tablet'------------------------------------------------ */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .header-content {
    display: flex;
    flex-direction: column;
    /* height: calc(220px - var(--bg-padding) - var(--bg-padding)); */
    justify-content: space-between;
  }
  .site-logo {
    width: 152px;
  }
  .site-logo.sm-logo {
    width: 130px !important;
    top: 25px;
  }

  img.header-bg {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 100%;
    bottom: 0px;
    object-fit: cover;
    object-position: center;
  }
  .scroll-down a {
    left: 48% !important;
  }
}
</style>
