<template>
  <div class="row single-post">
    <div class="col-lg-12 post-padding" v-if="!getting">
      <img v-lazy="post.meta.media.url" class="w-100 post-title-image" alt="" />
      <p class="lg-title mt-4">
        {{ post.post_title }}
      </p>
      <div class="top-border-primary"></div>
      <p class="text-bold sub-title post-author">
        {{ post.post_author }}
      </p>

      <p class="sm-sub-title post-date">
        {{ $moment(post.post_date).format("ll") }} .
        {{ post.meta["read-time"][0] }} min read
      </p>
      <share
        class="mt-3"
        :url="`${appUrl}/business-intelligence/${post.post_name}/${post.ID}`"
      />
      <p class="sm-sub-title mt-5 wp-content" v-html="post.post_content"></p>
      <div class="cate-box flex-align-center">
        <p
          v-for="(tag, index) in post.tags['cate-tags']"
          class="pointer"
          @click="
            $router.push({
              name: 'Business Intelligence',
              query: {
                tag: tag.name,
              },
            })
          "
          :key="index"
        >
          {{ tag.name }}
        </p>
      </div>
      <share
        class="mt-3"
        :url="`${appUrl}/business-intelligence/${post.post_name}/${post.ID}`"
      />
      <div
        class="sm-sub-title secondary mt-5 underline"
        @click="$router.push('/business-intelligence')"
      >
        BACK TO BUSINESS INTELLIGENCE
      </div>
    </div>

    <div class="col-lg-12 post-padding" v-if="getting">
      <img src="/image/loader.svg" class="w-100 post-title-image" alt="" />
      <img
        src="/image/loader.svg"
        class="object-fit-cover mt-4"
        alt=""
        style="width: 70%; height: 60px"
      />
      <div class="top-border-primary"></div>
      <img
        src="/image/loader.svg"
        class="object-fit-cover mt-4"
        alt=""
        style="width: 30%; height: 40px"
      />

      <img
        src="/image/loader.svg"
        class="object-fit-cover mt-4"
        alt=""
        style="width: 100%; height: 100vh"
      />
      <div
        class="sm-sub-title secondary mt-3 underline"
        @click="$router.push('/business-intelligence')"
      >
        BACK TO BUSINESS INTELLIGENCE
      </div>
    </div>
    <email />
    <Footer :data="footerData" />
  </div>
</template>

<script>
import share from "../common/share.vue";
import email from "../common/email.vue";
import Footer from "../common/footer.vue";

import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Post",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      post: {},
      appUrl: "",
      getting: true,
      footerData: [
        {
          url: "/contact-us",
          name: "Contact Us",
        },
        {
          url: "/who-we-are",
          name: "Who We Are",
        },
      ],
    };
  },
  components: {
    email,
    share,
    Footer,
  },
  created() {
    this.appUrl = window.location.origin;
    this.getPost();
  },
  methods: {
    getPost() {
      this.getting = true;
      const cacheData = this.$get.getCachedData(
        "/bussiness-intel/" + this.$route.params.id
      );
      if (cacheData) {
        this.post = cacheData;
        this.findEmbed();
        this.getting = false;
      }
      this.$get
        .getFreshData("/bussiness-intel/" + this.$route.params.id)
        .then((data) => {
          this.post = data;
          this.getting = false;
          this.findEmbed();
        });
    },
    findEmbed() {
      setTimeout(() => {
        const embed = document.querySelectorAll(".wp-block-embed__wrapper");
        if (embed) {
          embed.forEach((ele) => {
            if (ele.children.length == 0) {
              const iframe = `<iframe style="border:none;width:100%;height:100%;position:absolute;left:0px;top:0px;" src="${ele.innerText}" width='100%' height='100%'></iframe>`;
              ele.innerHTML = iframe;
            }
          });
        }
      }, 300);
    },
  },
};
</script>

<style >
.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.wp-embed-aspect-4-3 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 75%;
}
.wp-embed-aspect-3-2 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 66.66%;
}
.wp-embed-aspect-8-5 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 62.5%;
}
.wp-embed-aspect-1-1 .wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 100%;
}
blockquote p {
  font-size: 1.8rem;
}
figcaption {
  text-align: center;
}
html.wp-has-aspect-ratio,
body.wp-has-aspect-ratio,
body.wp-has-aspect-ratio > div,
body.wp-has-aspect-ratio > div iframe {
  height: 100%;
  overflow: hidden;
}
.wp-block-pullquote blockquote::before {
  color: currentColor;
  /* content: "“"; */
  display: block;
  position: relative;
  left: 0;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1;
}
.object-fit-cover {
  object-fit: cover;
}
.cate-box p {
  background: rgb(var(--primary));
  color: #fff;
  padding: 5px 8px;
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}
.cate-box p:hover {
  background: rgb(var(--secondary));
}
.post-title-image {
  max-height: 350px;
  object-fit: cover;
  object-position: center;
}
figure.wp-block-image {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
}
.wp-content > p {
  margin: 25px 0px !important;
}
figure.wp-block-image:not(.wp-block) {
  margin: 0;
}
.wp-block-image {
  position: relative;
}
.wp-block-image .is-applying img,
.wp-block-image.is-transient img {
  opacity: 0.3;
}
.wp-block-image figcaption img {
  display: inline;
}
.wp-block-image .components-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;
}
.wp-block-image:not(.is-style-rounded) > div {
  border-radius: inherit;
}
.wp-block-image .components-resizable-box__container {
  display: inline-block;
}
.wp-block-image .components-resizable-box__container img {
  display: block;
  width: inherit;
  height: inherit;
}
.block-editor-block-list__block[data-type="core/image"]
  .block-editor-block-toolbar
  .block-editor-url-input__button-modal {
  position: absolute;
  left: 0;
  right: 0;
  margin: -1px 0;
}
@media (min-width: 600px) {
  .block-editor-block-list__block[data-type="core/image"]
    .block-editor-block-toolbar
    .block-editor-url-input__button-modal {
    margin: -1px;
  }
}
[data-align="full"] > .wp-block-image img,
[data-align="wide"] > .wp-block-image img {
  width: 100%;
}
.wp-block[data-align="center"] > .wp-block-image,
.wp-block[data-align="left"] > .wp-block-image,
.wp-block[data-align="right"] > .wp-block-image {
  display: table;
}
.wp-block[data-align="center"] > .wp-block-image > figcaption,
.wp-block[data-align="left"] > .wp-block-image > figcaption,
.wp-block[data-align="right"] > .wp-block-image > figcaption {
  display: table-caption;
  caption-side: bottom;
}
.wp-block[data-align="left"] > .wp-block-image {
  margin: 0.5em 1em 0.5em 0;
}
.wp-block[data-align="right"] > .wp-block-image {
  margin: 0.5em 0 0.5em 1em;
}
.wp-block[data-align="center"] > .wp-block-image {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.wp-block-image__crop-area {
  position: relative;
  max-width: 100%;
  width: 100%;
}
.wp-block-image__crop-icon {
  padding: 0 8px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wp-block-image__crop-icon svg {
  fill: currentColor;
}
.wp-block-image__zoom .components-popover__content {
  overflow: visible;
  min-width: 260px;
}
.wp-block-image__zoom .components-range-control {
  flex: 1;
}
.wp-block-image__zoom .components-base-control__field {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
}
.wp-block-image__aspect-ratio {
  height: 46px;
  margin-bottom: -8px;
  display: flex;
  align-items: center;
}
.wp-block-image__aspect-ratio .components-button {
  width: 36px;
  padding-left: 0;
  padding-right: 0;
}
.wp-block-latest-posts {
  padding-left: 2.5em;
}
.wp-block-image {
  margin: 0 0 1em;
}
.wp-block-image img {
  max-width: 100%;
}
.wp-block-image:not(.is-style-rounded) img {
  border-radius: inherit;
}
.wp-block-image.aligncenter {
  text-align: center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  width: 100%;
}
.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright {
  display: table;
}
.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption {
  display: table-caption;
  caption-side: bottom;
}
.wp-block-image .alignleft {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}
.wp-block-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}
.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img {
  border-radius: 9999px;
}
@supports ((-webkit-mask-image: none) or (mask-image: none)) or
  (-webkit-mask-image: none) {
  .wp-block-image.is-style-circle-mask img {
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-mode: alpha;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    border-radius: 0;
  }
}
.wp-block-image figure {
  margin: 0;
}
ol.wp-block-latest-comments {
  margin-left: 0;
}
.wp-block-latest-comments .wp-block-latest-comments {
  padding-left: 0;
}
.wp-block-latest-comments__comment {
  line-height: 1.1;
  list-style: none;
  margin-bottom: 1em;
}
.has-avatars .wp-block-latest-comments__comment {
  min-height: 2.25em;
  list-style: none;
}
.has-avatars
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-excerpt,
.has-avatars
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  margin-left: 3.25em;
}
.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment {
  line-height: 1.5;
}
.wp-block-latest-comments__comment-excerpt p {
  font-size: 0.875em;
  line-height: 1.8;
  margin: 0.36em 0 1.4em;
}
.wp-block-latest-comments__comment-date {
  display: block;
  font-size: 0.75em;
}
.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: 0.75em;
  width: 2.5em;
}
.wp-block-latest-posts.alignleft {
  margin-right: 2em;
}
.wp-block-latest-posts.alignright {
  margin-left: 2em;
}
.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none;
  padding-left: 0;
}
.wp-block-latest-posts.wp-block-latest-posts__list li {
  clear: both;
}
.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.wp-block-latest-posts.is-grid li {
  margin: 0 1.25em 1.25em 0;
  width: 100%;
}
@media (min-width: 600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc(50% - 0.625em);
  }
  .wp-block-latest-posts.columns-2 li:nth-child(2n) {
    margin-right: 0;
  }
  .wp-block-latest-posts.columns-3 li {
    width: calc(33.33333% - 0.83333em);
  }
  .wp-block-latest-posts.columns-3 li:nth-child(3n) {
    margin-right: 0;
  }
  .wp-block-latest-posts.columns-4 li {
    width: calc(25% - 0.9375em);
  }
  .wp-block-latest-posts.columns-4 li:nth-child(4n) {
    margin-right: 0;
  }
  .wp-block-latest-posts.columns-5 li {
    width: calc(20% - 1em);
  }
  .wp-block-latest-posts.columns-5 li:nth-child(5n) {
    margin-right: 0;
  }
  .wp-block-latest-posts.columns-6 li {
    width: calc(16.66667% - 1.04167em);
  }
  .wp-block-latest-posts.columns-6 li:nth-child(6n) {
    margin-right: 0;
  }
}
.wp-block-latest-posts__post-author,
.wp-block-latest-posts__post-date {
  display: block;
  color: #555;
  font-size: 0.8125em;
}
.wp-block-latest-posts__post-excerpt {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.wp-block-latest-posts__featured-image a {
  display: inline-block;
}
.wp-block-latest-posts__featured-image img {
  height: auto;
  width: auto;
  max-width: 100%;
}
.wp-block-latest-posts__featured-image.alignleft {
  margin-right: 1em;
}
.wp-block-latest-posts__featured-image.alignright {
  margin-left: 1em;
}
.wp-block-latest-posts__featured-image.aligncenter {
  margin-bottom: 1em;
  text-align: center;
}
.block-editor-image-alignment-control__row .components-base-control__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block-editor-image-alignment-control__row
  .components-base-control__field
  .components-base-control__label {
  margin-bottom: 0;
}
ol.has-background,
ul.has-background {
  padding: 1.25em 2.375em;
}
.wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto;
}
.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%;
}
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  align-self: start;
}
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media,
.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media {
  align-self: center;
}
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  align-self: end;
}
.wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0;
}
.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8%;
  word-break: break-word;
}
.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
}
.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}
.wp-block-media-text__media img,
.wp-block-media-text__media video {
  max-width: unset;
  width: 100%;
  vertical-align: middle;
}
.wp-block-media-text.is-image-fill .wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}
.wp-block-media-text.is-image-fill .wp-block-media-text__media > a {
  display: block;
  height: 100%;
}
.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1;
  }
  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2;
  }
}
.wp-block-navigation {
  position: relative;
}
.wp-block-navigation ul,
.wp-block-navigation ul li {
  list-style: none;
  padding: 0;
}
.wp-block-navigation .wp-block-navigation-link,
.wp-block-navigation .wp-block-pages-list__item {
  display: flex;
  align-items: center;
  position: relative;
}
.wp-block-navigation
  .wp-block-navigation-link
  .wp-block-navigation-link__container:empty,
.wp-block-navigation
  .wp-block-pages-list__item
  .wp-block-navigation-link__container:empty {
  display: none;
}
.wp-block-navigation .wp-block-navigation-link__content,
.wp-block-navigation .wp-block-pages-list__item__link {
  color: inherit;
  display: block;
}
.wp-block-navigation[style*="text-decoration"] .wp-block-navigation-link,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-navigation-link__container,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-navigation-link__content,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-navigation-link__content:active,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-navigation-link__content:focus,
.wp-block-navigation[style*="text-decoration"] .wp-block-pages-list__item,
.wp-block-navigation[style*="text-decoration"] .wp-block-pages-list__item__link,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-pages-list__item__link:active,
.wp-block-navigation[style*="text-decoration"]
  .wp-block-pages-list__item__link:focus {
  text-decoration: inherit;
}
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-navigation-link__content,
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-navigation-link__content:active,
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-navigation-link__content:focus,
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-pages-list__item__link,
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-pages-list__item__link:active,
.wp-block-navigation:not([style*="text-decoration"])
  .wp-block-pages-list__item__link:focus {
  text-decoration: none;
}
.wp-block-navigation .wp-block-navigation-link__submenu-icon,
.wp-block-navigation .wp-block-page-list__submenu-icon {
  height: inherit;
}
.wp-block-navigation .wp-block-navigation-link__submenu-icon svg,
.wp-block-navigation .wp-block-page-list__submenu-icon svg {
  stroke: currentColor;
}
.wp-block-navigation
  .wp-block-navigation-link
  .wp-block-navigation-link__content.wp-block-navigation-link__content.wp-block-navigation-link__content,
.wp-block-navigation
  .wp-block-pages-list__item
  .wp-block-pages-list__item__link {
  padding: 0;
}
.wp-block-navigation .has-child > .wp-block-navigation-link__content,
.wp-block-navigation .has-child > .wp-block-pages-list__item__link {
  margin-right: 0.5em;
}
.wp-block-navigation .has-child .submenu-container,
.wp-block-navigation .has-child .wp-block-navigation-link__container {
  background-color: inherit;
  color: inherit;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  align-items: normal;
  min-width: 200px;
  display: none;
  opacity: 0;
  transition: opacity 0.1s linear;
  visibility: hidden;
}
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-navigation-link
  > .wp-block-navigation-link__content,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-navigation-link
  > .wp-block-pages-list__item__link,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-pages-list__item
  > .wp-block-navigation-link__content,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-pages-list__item
  > .wp-block-pages-list__item__link,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-navigation-link
  > .wp-block-navigation-link__content,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-navigation-link
  > .wp-block-pages-list__item__link,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-pages-list__item
  > .wp-block-navigation-link__content,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-pages-list__item
  > .wp-block-pages-list__item__link {
  flex-grow: 1;
}
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-navigation-link
  > .wp-block-navigation-link__submenu-icon,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-navigation-link
  > .wp-block-page-list__submenu-icon,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-pages-list__item
  > .wp-block-navigation-link__submenu-icon,
.wp-block-navigation
  .has-child
  .submenu-container
  > .wp-block-pages-list__item
  > .wp-block-page-list__submenu-icon,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-navigation-link
  > .wp-block-navigation-link__submenu-icon,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-navigation-link
  > .wp-block-page-list__submenu-icon,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-pages-list__item
  > .wp-block-navigation-link__submenu-icon,
.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  > .wp-block-pages-list__item
  > .wp-block-page-list__submenu-icon {
  padding-right: 0.5em;
}
@media (min-width: 782px) {
  .wp-block-navigation .has-child .submenu-container .submenu-container:before,
  .wp-block-navigation
    .has-child
    .submenu-container
    .wp-block-navigation-link__container:before,
  .wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .submenu-container:before,
  .wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .wp-block-navigation-link__container:before {
    content: "";
    position: absolute;
    right: 100%;
    height: 100%;
    display: block;
    width: 0.5em;
    background: transparent;
  }
  .wp-block-navigation
    .has-child
    .submenu-container
    .wp-block-navigation-link__submenu-icon
    svg,
  .wp-block-navigation
    .has-child
    .submenu-container
    .wp-block-page-list__submenu-icon
    svg,
  .wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .wp-block-navigation-link__submenu-icon
    svg,
  .wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .wp-block-page-list__submenu-icon
    svg {
    transform: rotate(-90deg);
  }
}
.wp-block-navigation .has-child:hover > .wp-block-navigation-link__container {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.wp-block-navigation
  .has-child:focus-within
  > .wp-block-navigation-link__container {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.wp-block-navigation .has-child:hover {
  cursor: pointer;
}
.wp-block-navigation .has-child:hover > .submenu-container {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.wp-block-navigation .has-child:focus-within {
  cursor: pointer;
}
.wp-block-navigation .has-child:focus-within > .submenu-container {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .wp-block-navigation-link__container {
  left: auto;
  right: 0;
}
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .submenu-container
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-right
  > .wp-block-navigation__container
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .submenu-container
  > .has-child:last-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.items-justified-space-between
  > .wp-block-navigation__container
  > .has-child:last-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container {
  left: auto;
  right: 100%;
}
.wp-block-navigation.wp-block-navigation
  .wp-block-navigation__container
  > .wp-block-navigation-link,
.wp-block-navigation.wp-block-navigation .wp-block-page-list,
.wp-block-navigation.wp-block-navigation
  .wp-block-page-list
  > .wp-block-pages-list__item {
  margin: 0 2em 0 0;
}
.wp-block-navigation.wp-block-navigation
  .wp-block-navigation__container
  > .wp-block-navigation-link:last-child,
.wp-block-navigation.wp-block-navigation .wp-block-page-list:last-child,
.wp-block-navigation.wp-block-navigation
  .wp-block-page-list
  > .wp-block-pages-list__item:last-child {
  margin-right: 0;
}
.wp-block-navigation.wp-block-navigation.has-background
  .wp-block-navigation__container
  > .wp-block-navigation-link,
.wp-block-navigation.wp-block-navigation.has-background .wp-block-page-list,
.wp-block-navigation.wp-block-navigation.has-background
  .wp-block-page-list
  > .wp-block-pages-list__item {
  margin: 0 0.5em 0 0;
}
.wp-block-navigation.wp-block-navigation.has-background
  .wp-block-navigation__container
  .wp-block-navigation-link__content,
.wp-block-navigation.wp-block-navigation.has-background
  .wp-block-page-list
  .wp-block-pages-list__item__link {
  padding: 0.5em 1em;
}
.wp-block-navigation.wp-block-navigation .has-child .submenu-container,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container {
  left: -1em;
  top: 100%;
}
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .wp-block-navigation-link,
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .wp-block-pages-list__item,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .wp-block-pages-list__item {
  margin: 0;
}
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .wp-block-navigation-link__content,
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .wp-block-pages-list__item__link,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__content,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .wp-block-pages-list__item__link {
  padding: 0.5em 1em;
}
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation
  .has-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container {
  left: -1px;
}
@media (min-width: 782px) {
  .wp-block-navigation.wp-block-navigation
    .has-child
    .submenu-container
    .submenu-container,
  .wp-block-navigation.wp-block-navigation
    .has-child
    .submenu-container
    .wp-block-navigation-link__container,
  .wp-block-navigation.wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .submenu-container,
  .wp-block-navigation.wp-block-navigation
    .has-child
    .wp-block-navigation-link__container
    .wp-block-navigation-link__container {
    left: 100%;
    top: -1px;
  }
}
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .submenu-container,
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .wp-block-navigation-link__container {
  left: 0;
  top: 100%;
}
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .submenu-container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .submenu-container
  .wp-block-navigation-link__container,
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .wp-block-navigation-link__container
  .submenu-container,
.wp-block-navigation.wp-block-navigation.has-background
  .has-child
  .wp-block-navigation-link__container
  .wp-block-navigation-link__container {
  left: 0;
}
@media (min-width: 782px) {
  .wp-block-navigation.wp-block-navigation.has-background
    .has-child
    .submenu-container
    .submenu-container,
  .wp-block-navigation.wp-block-navigation.has-background
    .has-child
    .submenu-container
    .wp-block-navigation-link__container,
  .wp-block-navigation.wp-block-navigation.has-background
    .has-child
    .wp-block-navigation-link__container
    .submenu-container,
  .wp-block-navigation.wp-block-navigation.has-background
    .has-child
    .wp-block-navigation-link__container
    .wp-block-navigation-link__container {
    left: 100%;
    top: 0;
  }
}
.wp-block-navigation:not(.has-background) .submenu-container,
.wp-block-navigation:not(.has-background)
  .wp-block-navigation__container
  .wp-block-navigation-link__container {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.wp-block-navigation__container {
  align-items: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.wp-block-navigation__container .is-responsive {
  display: none;
}
.wp-block-image figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}
figcaption {
  text-align: center;
}
.is-dark-theme .wp-block-image figcaption {
  color: hsla(0, 0%, 100%, 0.65);
}
figure.wp-block-image img {
  width: 100%;
  object-fit: contain;
}
/* ------------------------------------------------'mobile'------------------------------------------------ */
@media screen and (max-width: 600px) {
  figure.wp-block-pullquote {
    margin: 10px 0px !important;
  }
  blockquote {
    margin: 10px 0px !important;
  }
  blockquote p {
    font-size: 1.2rem;
  }
}
</style>