export default {
  install(Vue) {
    Vue.directive("toggle-header", {
      mounted(el, binding) {
        const { value } = binding;
        const app = document.querySelector(".app");
        const header = document.querySelector(".lower-header");
        let onPageScroll = () => {
          let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
          const offsetTop = el.offsetTop - header.clientHeight;
          if (
            scrollTop >= offsetTop &&
            scrollTop <= offsetTop + el.clientHeight
          ) {
            app.classList.add(value.header);
            app.classList.add(value.logo);
          } else {
            app.classList.remove(value.header);
            app.classList.remove(value.logo);
          }
          var elementExists = document.getElementById(el.id);
          if (!elementExists) {
            window.removeEventListener("scroll", onPageScroll);
          }
        };

        window.addEventListener("scroll", onPageScroll, false);
      },
    });
  },
};
