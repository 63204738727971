import {
  createRouter,
  // createWebHashHistory,
  createWebHistory,
} from "vue-router";
// import store from "../store";
import Home from "../../components/pages/home.vue";
import WhoWeAre from "../../components/pages/whoweare.vue";
import Innovative from "../../components/pages/innovation.vue";
import Decentralised from "../../components/pages/decentralised.vue";
import Integrated from "../../components/pages/integrated.vue";
import Login from "../../components/pages/login.vue";
import Member from "../../components/pages/member-portal.vue";
import Map from "../../components/pages/singleMap.vue";
import BusinessIntel from "../../components/pages/businessIntel.vue";
import Post from "../../components/pages/post.vue";
import ContactUs from "../../components/pages/contactUs.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      class: "home-page",
    },
  },
  {
    path: "/who-we-are",
    name: "Who We Are",
    component: WhoWeAre,
    meta: {
      class: "who-we-are-page",
    },
  },
  {
    path: "/innovative-finance",
    name: "Innovative Finance",
    component: Innovative,
    meta: {
      class: "innovation-finance-page",
    },
  },
  {
    path: "/decentralised-renewable-solutions",
    name: "Decentralised Renewable Solutions",
    component: Decentralised,
    meta: {
      class: "decentralised-renewable-solutions-page",
    },
  },
  {
    path: "/integrated-electrification",
    name: "Integrated Electrification",
    component: Integrated,
    meta: {
      class: "integrated-electrification-page",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      class: "integrated-electrification-page",
      requiresVisitor: true,
    },
  },
  {
    path: "/member-portal",
    name: "Member Portal",
    component: Member,
    meta: {
      class: "integrated-electrification-page",
      requiresAuth: true,
    },
  },
  {
    path: "/map/:slug",
    name: "Single Map",
    component: Map,
    meta: {
      class: "integrated-electrification-page",
      requiresAuth: true,
    },
  },
  {
    path: "/business-intelligence",
    name: "Business Intelligence",
    component: BusinessIntel,
    meta: {
      class: "business-intelligence-page",
    },
  },
  {
    path: "/business-intelligence/:id/:name",
    name: "Post",
    component: Post,
    meta: {
      class: "business-intelligence-page",
    },
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    meta: {
      class: "contact-us-page",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    } else {
      return { top: 0 };
    }
  },
});

function isLoggedIn() {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("isLoggedIn="));
  if (cookieValue) {
    cookieValue.split("=")[1];
    return cookieValue.split("=")[1];
  } else {
    return false;
  }
}

router.beforeEach((to, from, next) => {
  // store.commit("setLoading", true);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      localStorage.setItem("redirect", to.fullPath);
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isLoggedIn()) {
      next({
        name: "Member Portal",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
