<template>
  <div class="business-card mb-sm-4">
    <div class="card-border" :style="`background:${color}`"></div>
    <p
      class="card-title"
      :style="`color:${
        color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
          ? `rgb(var(--secondary))`
          : color
      }`"
    >
      <span v-html="post.post_title"></span>
    </p>
    <p class="my-2 sm-text">
      <span
        v-for="(tag, index) in post.tags['cate-tags']"
        class="pointer"
        @click="
          $router.push({
            name: 'Business Intelligence',
            query: {
              tag: tag.slug,
            },
          })
        "
        :key="index"
        ><span v-html="tag.name"></span>
        <span v-if="index !== post.tags['cate-tags'].length - 1">
          |
        </span></span
      >
    </p>
    <div class="d-flex" :class="!post.tags['cate-tags'] && 'mt-3'">
      <a
        class="mr-3 sm-text underline"
        v-if="post.meta['myanmar-file']"
        target="_blank"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
        :href="post.meta['myanmar-file']"
        >Myanmar
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.64285 0.652344V9.66303L1.46829 7.31585L0.760277 8.09824L4.84599 11.1228L5.2 11.3854L5.55401 11.1228L9.63972 8.09824L8.9317 7.31585L5.75714 9.66303V0.652344H4.64285ZM0 11.4064V12.4146H10.4V11.4064H0Z"
            :fill="
              color == '#0D2E4A' ||
              color == '#0d2e4a' ||
              color == 'rgb(13, 46, 74)'
                ? `rgb(var(--secondary))`
                : color
            "
          />
        </svg>
      </a>
      <a
        v-if="post.meta['english-file']"
        :href="post.meta['english-file']"
        target="_blank"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
        class="sm-text underline mr-3"
      >
        English
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.64285 0.652344V9.66303L1.46829 7.31585L0.760277 8.09824L4.84599 11.1228L5.2 11.3854L5.55401 11.1228L9.63972 8.09824L8.9317 7.31585L5.75714 9.66303V0.652344H4.64285ZM0 11.4064V12.4146H10.4V11.4064H0Z"
            :fill="
              color == '#0D2E4A' ||
              color == '#0d2e4a' ||
              color == 'rgb(13, 46, 74)'
                ? `rgb(var(--secondary))`
                : color
            "
          /></svg
      ></a>

      <p
        v-if="
          post.post_content &&
          !post.meta['read-more'] &&
          (!post.meta['english-file'] || !post.meta['english-file'])
        "
        class="sm-text mr-3 pointer underline"
        @click="
          $router.push({
            name: 'Post',
            params: {
              name: post.post_name,
              id: post.ID,
            },
          })
        "
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
      >
        Read more
      </p>

      <a
        v-if="
          post.meta['read-more'] &&
          (!post.meta['english-file'] || !post.meta['english-file'])
        "
        class="sm-text mr-3 pointer underline"
        :href="post.meta['read-more']"
        target="_blank"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
      >
        Read more
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    post: {},
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      getting: true,
    };
  },
  mounted() {
    this.sortArray();
  },
  methods: {
    sortArray() {
      var tags = this.post.tags["cate-tags"];
      if (tags)
        tags.sort(function (a, b) {
          var dateA = moment(a.name);
          var dateB = moment(b.name);
          if (dateA.isValid()) {
            return 1;
          }
          if (dateB.isValid()) {
            return -1;
          }
          return 0;
        });
    },
  },
};
</script>

<style scoped>
.business-card {
  padding: 0px 20px;
  position: relative;
}
.card-border {
  width: 3%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 3px;
}
</style>