<template>
  <div
    class="row mb-0 mt-5"
    style="margin-top: var(--bg-padding)"
    v-if="!getting && data.data && data.data.length > 0"
  >
    <div class="col-lg-12">
      <p class="sm-title" :style="`color:${type.color}`">
        {{ type.name }}
      </p>
    </div>
    <div
      class="col-lg-3 mt-md-5 col-md-6 mt-3"
      v-for="item in data.data"
      :key="item.ID"
    >
      <businessCardPost :key="item.ID" :post="item" :color="type.color" />
    </div>
    <div class="w-100 mt-5" v-if="hasNext">
      <button
        @click="loadMore"
        :style="'border-color:' + type.color + ';color:' + type.color"
        class="input load-more normal-text"
      >
        <p v-if="!loading">Read more</p>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          :stroke="type.color"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          class="loading-icon"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="12" y1="2" x2="12" y2="6"></line>
          <line x1="12" y1="18" x2="12" y2="22"></line>
          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
          <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
          <line x1="2" y1="12" x2="6" y2="12"></line>
          <line x1="18" y1="12" x2="22" y2="12"></line>
          <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
          <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
        </svg>
      </button>
    </div>
  </div>
  <div class="row" v-if="getting" style="margin-top: var(--bg-padding)">
    <div class="col-lg-3 col-md-6 loader-box" v-for="i in 4" :key="i">
      <img src="/image/loader.svg" class="w-100" alt="" />
    </div>
  </div>
</template>

<script>
import businessCardPost from "./businessCardPost.vue";

export default {
  emits: ["updatedData"],
  props: {
    type: {},
  },
  data() {
    return {
      data: {
        data: [],
        max_pages: 0,
        current_page: 1,
      },
      getting: true,
      loading: false,
    };
  },
  components: {
    businessCardPost,
  },
  watch: {},
  mounted() {
    this.getBusiness();
  },
  computed: {
    hasNext() {
      return parseInt(this.data.max_pages) > parseInt(this.data.current_page);
    },
  },
  methods: {
    getBusiness() {
      this.getting = true;
      var url =
        (url = `/bussiness-intelligence/${this.type.term_id}/?paged=${this.data.current_page}&per_page=8`);
      if (this.$route.query.tag) {
        url = `/bussiness-intelligence/${this.type.term_id}/?paged=${this.data.current_page}&tag=${this.$route.query.tag}&per_page=8`;
      }
      const cacheData = this.$get.getCachedData(url);
      if (cacheData && cacheData.data) {
        this.data = cacheData;
        this.getting = false;
        this.$emit("updatedData", cacheData.data.length !== 0);
      } else {
        this.$emit("updatedData", false);
      }
      this.$get.getFreshData(url).then((data) => {
        this.data = data;
        this.getting = false;
        if (data.data) {
          this.$emit("updatedData", data.data.length !== 0);
        } else {
          this.$emit("updatedData", false);
        }
      });
    },
    loadMore() {
      if (this.type) {
        this.loading = true;
        var url = (url = `/bussiness-intelligence/${this.type.term_id}/?paged=${
          parseInt(this.data.current_page) + 1
        }&per_page=8`);
        if (this.$route.query.tag) {
          url = `/bussiness-intelligence/${this.type.term_id}/?paged=${
            parseInt(this.data.current_page) + 1
          }&tag=${this.$route.query.tag}&per_page=8`;
        }
        this.$axios
          .get(url)
          .then((res) => {
            this.data.data.push(...res.data.data);
            this.data.current_page = res.data.current_page;
            this.data.max_pages = res.data.max_pages;
            this.loading = false;
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
button.input.load-more {
  height: 38px;
  width: 180px !important;
}
.loader-box {
  height: 150px;
  margin: 30px 0px;
  overflow: hidden;
}
.loader-box {
  height: 150px;
  margin: 30px 0px;
  overflow: hidden;
}
/* ------------------------------------------------"desktop"------------------------------------------------ */
@media (min-width: 992px) {
  .recent-bussiness-box .col-lg-5 {
    max-width: 38% !important;
  }
  .recent-bussiness-box .col-lg-2 {
    max-width: 24% !important;
    flex: 0 0 24%;
  }
}
</style>