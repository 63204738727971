import ScrollAnimate from "./scroll-animate";

export default {
  install(Vue) {
    Vue.directive("scroll-ani", {
      mounted(el) {
        const scrollAnimate = ScrollAnimate();
        let lastScrollTop = window.pageYOffset;
        window.addEventListener(
          "scroll",
          function() {
            let scrollTop =
              window.pageYOffset || document.documentElement.scrollTop;
            const isUpwards = scrollTop < lastScrollTop;
            scrollAnimate.run(el, { isUpwards });
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
          },
          false
        );
      },
    });
  },
};
