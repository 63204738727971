export default () => {
  const getClientHeight = () => document.documentElement.clientHeight;
  let isAnimating = false;
  const isInScrollView = ({ top, bottom }) =>
    top < getClientHeight() && bottom > 0;

  const hasBeenApplied = (el) => {
    return el.classList.contains("applied");
  };
  const padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  const applyAnimationClass = (el, value, duration, prefix, surfix) => {
    let startTimestamp = null;
    let start = 0;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const output = padLeadingZeros(
        Math.floor(progress * (value - start) + start),
        value.toString().length
      );
      const outputWithComma = output
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      el.innerText = prefix + outputWithComma + surfix;
      el.classList.add("applied");
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  return {
    isInView: isInScrollView,
    run(el, value, duration, prefix, surfix) {
      if (!this.isInView(el.getBoundingClientRect()) && !isAnimating) {
        return;
      }

      if (!hasBeenApplied(el)) {
        return applyAnimationClass(el, value, duration, prefix, surfix);
      }
    },
  };
};
