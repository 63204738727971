import get from "../axios/get";
import moment from "moment";

export default {
  logout({ commit }) {
    commit("setCurrentUser", null);
  },
  getTaxos({ commit }) {
    const cacheData = get.getCachedData("taxos?per_page=100");
    if (cacheData && cacheData.length > 0) {
      var reports = cacheData.filter((data) => data.slug == "reports")[0];
      commit("setReports", reports);
      const _reportsUrl = `/bussiness-intelligence/${reports.term_id}/?paged=1`;
      get.getFreshData(_reportsUrl);
      var tech = cacheData.filter((data) => data.slug == "technical-briefs")[0];
      commit("setTech", tech);
      const _techUrl = `/bussiness-intelligence/${tech.term_id}/?paged=1`;
      get.getFreshData(_techUrl);

      var artical = cacheData.filter(
        (data) => data.slug == "articles-case-studies-more"
      )[0];
      commit("setArtical", artical);
      const _articalUrl = `/bussiness-intelligence/${artical.term_id}/?paged=1`;
      get.getFreshData(_articalUrl);

      var myanmar = cacheData.filter(
        (data) => data.slug == "myanmar-electrification-library"
      )[0];
      commit("setMyanmar", myanmar);
    }
    get.getFreshData("taxos?per_page=100").then((res) => {
      var reposts = res.filter((data) => data.slug == "reports")[0];
      commit("setReports", reposts);

      var tech = res.filter((data) => data.slug == "technical-briefs")[0];
      commit("setTech", tech);
      var artical = res.filter(
        (data) => data.slug == "articles-case-studies-more"
      )[0];
      commit("setArtical", artical);

      var myanmar = res.filter(
        (data) => data.slug == "myanmar-electrification-library"
      )[0];
      commit("setMyanmar", myanmar);
    });
  },
  getCatTags({ commit }) {
    const cacheData = get.getCachedData("cat-tags");
    if (cacheData && cacheData.length > 0) {
      var subjects = [];
      var years = [];
      cacheData.forEach((data) => {
        var date = moment(data.name);
        if (date.isValid()) {
          years.push(data);
        } else {
          subjects.push(data);
        }
      });
      commit("setYears", years);
      commit("setSubjects", subjects);
      commit("setIsReady", true);
    }
    get.getFreshData("cat-tags").then((res) => {
      var subjects = [];
      var years = [];
      res.forEach((data) => {
        var date = moment(data.name);
        if (date.isValid()) {
          years.push(data);
        } else {
          subjects.push(data);
        }
      });
      commit("setYears", years);
      commit("setSubjects", subjects);

      commit("setIsReady", true);
    });
  },

  // getCatTags({ commit }) {
  //   const cacheData = get.getCachedData("cat-tags");
  //   if (cacheData && cacheData.length > 0) {
  //     commit("setSubjects", cacheData);
  //     commit("setIsReady", true);
  //   }
  //   get.getFreshData("cat-tags").then((res) => {
  //     commit("setSubjects", res);

  //     commit("setIsReady", true);
  //   });
  // },

  getFacts({ commit }) {
    const cacheData = get.getCachedData("facts?per_page=100");
    if (cacheData) {
      commit("setFacts", cacheData);
    }
    get.getFreshData("facts?per_page=100").then((res) => {
      commit("setFacts", res);
    });
  },
  getAccordions({ commit }) {
    const cacheData = get.getCachedData("accordion?per_page=100");
    if (cacheData) {
      commit("setAccordion", cacheData);
      cacheData.forEach((data) => {
        commit("preloadImage", data.image.url);
      });
    }
    get.getFreshData("accordion?per_page=100").then((res) => {
      commit("setAccordion", res);
      res.forEach((data) => {
        commit("preloadImage", data.image.url);
      });
    });
  },
};
