<template >
  <div
    class="container-fluid app"
    :class="
      $route.meta.class +
      ' ' +
      ($route.name == 'Member Portal' &&
        'dark-header-member-portal-page light-logo-member-portal-page')
    "
  >
    <metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
    <Header />
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.fullPath" />
    </router-view>
  </div>
</template>

<script>
import Header from "./components/common/header.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { Header },
  data() {
    //resizeHandelar start

    //resizeHandelar end

    return {
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
      offsetTop: "",
      routeName: "Home",
    };
  },
  computed: {
    ...mapState(["loading", "isReady"]),
  },
  watch: {
    $route(val) {
      this.routeName = val.name;
    },
  },
  created() {
    // this.$store.dispatch("getFacebookDetail");

    this.routeName = this.$route.name;
    if (this.routeName !== "Single Map") {
      this.$store.dispatch("getFacts");
      this.$store.dispatch("getAccordions");
      this.$store.dispatch("getTaxos");
      this.$store.dispatch("getCatTags");
    }
  },
  mounted() {
    window.addEventListener("resize", this.setDimensions);
  },

  methods: {
    setDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = document.documentElement.clientHeight;
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.setDimensions);
    },
    // if (this.isReady) {
    //   const loader = document.querySelector(".page-loader");
    //   loader.className += " hidden";
    //   setTimeout(function () {
    //     loader.className += " display_none";
    //   }, 1000);
    // }
  },

  // methods: {},
};
</script>

<style>
.page-fade-enter-active,
.page-fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.page-fade-enter-from,
.page-fade-leave-to {
  opacity: 0;
}
/* .home-page {
  background: rgb(var(--primary));
} */
</style>
