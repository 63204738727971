import get from "../axios/get";

const urls = [
  "home",
  "who-we-are",
  "innovative-finance",
  "integrated-electrifi",
  "decentralised",
  "business-intelligenc",
  "login",
  "member-portal",
  "content-us",
];

export default {
  setUser(state, data) {
    state.user = data;
    state.isLoggedIn = true;
  },
  setReports(state, data) {
    state.reports = data;
  },
  setFacebook(state, data) {
    state.facebook = data;
  },
  setTech(state, data) {
    state.tech = data;
  },
  setArtical(state, data) {
    state.artical = data;
  },
  setMyanmar(state, data) {
    state.myanmar = data;
  },
  setIsReady(state, data) {
    state.isReady = data;
  },
  setHomeHeader(state, data) {
    state.homeHeader = data;
  },
  setFacts(state, data) {
    state.facts = data;
  },
  setYears(state, data) {
    state.years = data;
  },
  setSubjects(state, data) {
    state.subjects = data;
  },
  setAccordion(state, data) {
    state.accordion = data;
  },
  preloadImage(state, url) {
    var img = new Image();
    img.src = url;
  },
  scrollToHash(state, url) {
    setTimeout(() => {
      const ele = document.querySelector(url.hash);
      const header = document.querySelector(".lower-header");
      window.scrollTo(0, ele.offsetTop - header.clientHeight);
    }, 1000);
  },
  getData() {
    if (!localStorage.getItem("gotData")) {
      urls.forEach((url) => {
        get.getFreshData(url);
      });
      localStorage.setItem("gotData", true);
    }
  },
};
