<template>
  <div class="col-lg-12 pt-0 mt-md-5 bg-padding">
    <div class="top-border-primary mb-sm-2"></div>
    <p class="lg-title mb-sm-3">Recent business intelligence</p>
    <div
      class="row bg-padding recent-bussiness-box pb-0 px-0"
      v-if="!getting && businesses.length > 1"
    >
      <div class="col-lg-5 mb-sm-5 col-md-4-5">
        <businessCard
          :color="businesses[0].tags['kind-of-report'][0].color"
          :data="businesses[0]"
          v-if="
            !getting && businesses[0] && businesses[0].tags['kind-of-report'][0]
          "
        />
      </div>
      <div
        class="col-lg-5 col-md-4-5 mb-sm-5 right-divider"
        style="border-color: rgb(var(--lighter-primary)) !important"
      >
        <businessCard
          :color="businesses[1].tags['kind-of-report'][0].color"
          :data="businesses[1]"
          v-if="
            !getting && businesses[1] && businesses[1].tags['kind-of-report'][0]
          "
        />
      </div>
      <div
        class="
          col-lg-2 col-md-3
          mb-sm-4
          mt-sm-4
          more-posts-link
          text-center text-sm-left
        "
      >
        <router-link
          :to="`/business-intelligence`"
          class="secondary sub-title underline text-bold"
          >Read more here</router-link
        >
      </div>
    </div>

    <div class="row bg-padding recent-bussiness-box pb-0 px-0" v-if="getting">
      <div class="col-lg-5 col-md-4-5 loader-box" v-for="i in 2" :key="i">
        <img
          src="/image/loader.svg"
          style="max-height: 150px"
          class="w-100"
          alt=""
        />
      </div>
      <div class="col-lg-2 col-md-3 flex-center">
        <router-link
          :to="`/business-intelligence`"
          class="secondary sub-title underline text-bold"
          >Read more here</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import businessCard from "./businessCard.vue";
export default {
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      businesses: [],
      getting: true,
    };
  },
  components: {
    businessCard,
  },
  created() {
    this.getBusiness();
  },
  methods: {
    getBusiness() {
      this.getting = true;
      const cacheData = this.$get.getCachedData(
        "/bussiness-intelligence/latest?tag=" + this.tag
      );
      if (cacheData) {
        this.businesses = cacheData;
        this.getting = false;
      }
      this.$get
        .getFreshData("/bussiness-intelligence/latest?tag=" + this.tag)
        .then((data) => {
          this.businesses = data.data;
          this.getting = false;
        });
    },
  },
};
</script>

<style scoped>
/* ------------------------------------------------"desktop"------------------------------------------------ */
@media (min-width: 992px) {
  .recent-bussiness-box .col-lg-5 {
    max-width: 38% !important;
  }
  .recent-bussiness-box .col-lg-2 {
    max-width: 24% !important;
    flex: 0 0 24%;
  }
  .more-posts-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.map img {
  height: 100%;
  object-fit: cover;
}
</style>