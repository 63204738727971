<template>
  <div class="col-lg-12 bg-padding overflow-hidden">
    <div class="secondary-border mt-0 mt-md-5 mb-sm-2 top-border-primary"></div>
    <div class="row" v-if="getting">
      <div class="col-lg-4 col-md-4 loader-box" v-for="i in 3" :key="i">
        <img src="/image/darkLoader.svg" class="w-100" alt="" />
      </div>
    </div>
    <div class="row px-md-2" v-if="!getting">
      <div
        class="col-lg-4 col-md-4 px-md-2"
        v-for="(map, i) in maps"
        :key="i + map.post_title"
        :style="`z-index:${index == i ? 1000 : 1}`"
      >
        <div class="map pointer my-md-3 mx-md-0" @click="toggle(i)">
          <img v-lazy="map.featured_image" class="w-100" alt="" />
          <p
            class="map-title text-truncate sm-sub-title"
            :class="index == i && 'secondary-bg text-white'"
          >
            <span v-html="map.post_title"></span>
          </p>
        </div>

        <transition name="fade" class="sm-hide">
          <div
            class="flex-center position-absolute"
            style="width: calc(100% - 30px) !important; z-index: 1"
            v-if="index == i"
          >
            <div v-scroll-ani class="scroll-ani top">
              <svg
                width="16"
                height="16"
                class="scroll-ani-circle"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="7"
                  stroke="rgb(var(--secondary))"
                  stroke-width="2"
                />
              </svg>

              <svg
                width="2"
                height="44px"
                class="scroll-ani-line"
                viewBox="0 0 2 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="4.37114e-08"
                  x2="0.999996"
                  y2="100"
                  stroke="rgb(var(--secondary))"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
        </transition>
        <transition
          name="accordion"
          v-on:before-enter="beforeEnter"
          v-on:before-leave="beforeLeave"
          v-on:leave="leave"
        >
          <div
            v-if="index == i"
            class="accordion-item-container"
            v-setMapHeight
            :key="i"
          >
            <div class="accordion-item-content">
              <div class="row bg2 py-md-5 bg-padding">
                <div class="col-lg-6 col-md-6">
                  <img :src="map.preview" class="w-100" alt="" />
                </div>
                <div
                  class="
                    col-lg-6 col-md-6
                    justify-content-center
                    align-items-start
                    flex-column
                    d-flex
                  "
                >
                  <p class="title map-content-title my-sm-4 mb-3 primary">
                    <span v-html="map.post_title"></span>
                  </p>
                  <p class="normal-text">
                    <span v-html="map.descriptions"></span>
                  </p>
                  <p
                    @click="goToMap(map.slug)"
                    class="
                      sm-sub-title
                      mb-sm-4
                      secondary
                      mt-3
                      underline-text
                      text-uppercase
                      pointer
                    "
                  >
                    ACCESS <span v-html="map.post_title"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      maps: [],
      index: null,
      getting: true,
    };
  },
  computed: {
    ...mapGetters(["isLg"]),
  },
  components: {},
  created() {
    this.getMaps();
  },
  methods: {
    getMaps() {
      this.getting = true;
      var uid = document.cookie
        .split("; ")
        .find((row) => row.startsWith("uid="));
      if (uid) {
        uid = uid.split("=")[1];
      }

      const cacheData = this.$get.getCachedData("/maps/" + uid);
      if (cacheData) {
        this.maps = cacheData;
        this.maps.forEach((map) => {
          this.$store.commit("preloadImage", map.preview);
        });
        this.getting = false;
      }
      this.$get.getFreshData("/maps/" + uid).then((res) => {
        this.maps = res;
        this.maps.forEach((map) => {
          this.$store.commit("preloadImage", map.preview);
        });
        this.getting = false;
      });
    },
    goToMap(slug) {
      let routeData = this.$router.resolve({
        name: "Single Map",
        params: {
          slug: slug,
        },
      });
      window.open(routeData.href, "_blank");
    },
    toggle(i) {
      if (this.index == i) {
        i = null;
      }
      if (this.isLg && this.index !== null) {
        this.index = null;
        setTimeout(() => {
          this.index = i;
        }, 600);
      } else {
        this.index = i;
      }
    },
    beforeEnter: function (el) {
      el.style.height = "0";
    },
    // enter: function (el) {
    //   el.style.height = el.scrollHeight + "px";
    // },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
    setHeight(e, i) {
      const parent = document.getElementById(`map${i}`);
      parent.path[0].style.height = e.path[0].style.height + "px";
    },
  },
};
</script>

<style scoped>
.map img {
  height: 100%;
  object-fit: cover;
}
.loader-box {
  height: 216px;
  margin: 30px 0px;
  padding: 0px 35px;
  overflow: hidden;
}
.map:hover p.map-title {
  background: rgb(var(--secondary));
  color: rgb(var(--white));
}
p.map-title {
  position: absolute;
  background: rgb(var(--background-2));
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  transition: 0.45s all ease-in-out;
}
.scroll-ani.top > svg.scroll-ani-circle {
  top: -15px;
}
.scroll-ani.bottom > svg.scroll-ani-circle {
  top: 44px;
}

.map {
  height: 216px;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: flex-end;
  margin: 30px 20px;
}
.accordion-item-content {
  padding-top: 30px;
}
.accordion-item-container {
  overflow: hidden;
  width: 100vw;
  position: relative;
  transition: 0.45s all ease-in-out;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>