<template>
  <div class="tetimoni-box row">
    <div class="testi-img my-md-3 col-lg-3 col-md-3">
      <img v-lazy="item.photo.url" @load="setHeight" class="w-100" alt="" />
    </div>
    <div class="testi-content my-md-3 col-lg-9 col-md-9">
      <svg
        width="25"
        height="15"
        viewBox="0 0 25 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00019 0L10.3502 1.57407C6.52514 2.77778 4.95011 4.44444 4.95011 6.01852C4.95011 6.94444 5.51262 7.22222 7.31265 7.77778C8.43768 8.14815 10.0127 9.07407 10.0127 10.9259C10.0127 13.0556 7.98767 14.7222 5.28761 14.7222C2.25005 14.7222 0 12.4074 0 9.25926C0 5 3.37507 1.2963 9.00019 0ZM22.838 0L24.188 1.57407C20.3629 2.77778 18.7879 4.35185 18.7879 6.01852C18.7879 6.94444 19.3504 7.22222 21.1504 7.77778C22.163 8.14815 23.8505 9.07407 23.8505 10.9259C23.8505 13.0556 21.8255 14.7222 19.1254 14.7222C16.0878 14.7222 13.8378 12.5 13.8378 9.25926C13.9503 5 17.2129 1.2963 22.838 0Z"
          fill="rgb(var(--primary))"
        />
      </svg>
      <p class="primary mt-2 mb-4 sub-title">
        <span v-html="item.speech"></span>
      </p>
      <p class="sub-title mb-1">
        <span v-html="item.name"></span>
      </p>
      <p class="sm-text mb-1">
        <span v-html="item.position"></span>
      </p>
      <p class="sm-text mb-1">
        <span v-html="item.work_location"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
  mounted() {},
  methods: {
    setHeight(e) {
      e.path[0].style.height = e.path[0].clientWidth + "px";
    },
  },
};
</script>

<style >
.testi-img img {
  border-radius: 100%;
}
.tetimoni-box {
  margin: 30px 0px;
}
.testi-content {
  text-align: start !important;
  padding-left: 25px;
  height: 100%;
}
</style>