<template>
  <div class="row" v-if="!getting">
    <div
      class="col-lg-12 bg-padding pb-0 white-bg"
      id="home-section-2"
      v-toggle-header="{
        header: 'light-header-home-section-2',
        logo: 'dark-logo-home-section-2',
      }"
    >
      <div class="row pt-md-5">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <p class="number-label primary mb-5">
            <span v-html="post['section3-title']"></span>
          </p>
          <div class="top-border-primary mt-0"></div>
          <p class="primary lg-title">
            <span v-counter v-html="post['val-1']"></span>
          </p>
          <p class="sub-title md-sm-sub-title mb-5 primary">
            <span v-html="post['val-1-des']"></span>
          </p>

          <div class="top-border-primary mt-0"></div>

          <p class="primary lg-title">
            <span v-counter v-html="post['val-2']"></span>
          </p>
          <p class="sub-title md-sm-sub-title primary mb-5">
            <span v-html="post['val-2-des']"></span>
          </p>

          <div class="top-border-primary mt-0"></div>

          <p class="primary lg-title">
            <span v-counter v-html="post['val-3']"></span>
          </p>
          <p class="sub-title md-sm-sub-title mb-5 primary">
            <span v-html="post['val-3-des']"></span>
          </p>

          <router-link
            :to="post['section3-link']"
            class="
              underline-text
              mt-3
              secondary
              sub-title
              md-sm-sub-title
              d-inline-block
              mb-5
            "
          >
            <span v-html="post['section3-link-title']"></span>
          </router-link>
        </div>

        <div class="col-lg-8 col-md-8 col-sm-12">
          <img :src="post['section3-image'].url" class="w-100 map-img" alt="" />
          <router-link
            :to="post['section3-link']"
            class="
              underline-text
              mt-3
              secondary
              normal-text
              d-inline-block
              mb-5
              lg-hide
              md-hide
              sm-hide
            "
          >
            <span v-html="post['section3-link-title']"></span>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 bg-padding primary-bg pb-sm-0 pb-md-0"
      id="home-section-1"
      v-toggle-header="{
        header: 'dark-header-home-section-1',
        logo: 'light-logo-home-section-1',
      }"
    >
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="row pb-md-5">
            <div
              class="
                col-lg-4
                pr-md-0
                col-md-4-5
                d-flex
                align-items-start
                flex-column
              "
            >
              <p class="title text-white mb-3">
                <span v-html="post['section1-title']"></span>
              </p>
              <img
                v-lazy="post['section1-image'].url"
                class="w-100 lg-hide md-hide mb-4"
                alt=""
              />
              <p class="sub-title md-sm-sub-title text-white mb-3">
                <span v-html="post['section1-subtitle']"></span>
              </p>
              <p class="normal-text text-white mb-3">
                <span v-html="post['section1-content']"></span>
              </p>
              <a
                :href="post['section1-learn-more']"
                class="underline-text secondary normal-text"
                target="_blank"
              >
                LEARN MORE</a
              >
              <!-- <router-link
                :to="post['section1-learn-more']"
                class="underline-text secondary normal-text"
              >
                LEARN MORE</router-link
              > -->
            </div>
            <div class="col-lg-8 col-md-7-5 pl-md-4 sm-hide">
              <img
                :src="post['section1-image'].url"
                style=""
                class="w-100 home-s2-img"
                alt=""
              />
            </div>
            <div class="col-12">
              <div class="bg-divider mx-0 my-md-5 my-sm-5"></div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4 col-md-3">
                  <img
                    :src="post['section2-image'].url"
                    class="w-100 s2-img"
                    alt=""
                  />
                </div>
                <div
                  class="
                    col-lg-4 col-md-4-5
                    right-divider
                    sm-hide-border
                    d-flex
                    align-items-start
                    flex-column
                  "
                >
                  <p class="sub-title text-white md-sm-sub-title mb-2">
                    <span v-html="post['section2-title']"></span>
                  </p>
                  <p class="normal-text text-white mb-2">
                    <span v-html="post['section2-content']"></span>
                  </p>
                  <a
                    :href="post['section2-learn-more']"
                    class="underline-text secondary normal-text"
                    target="_blank"
                  >
                    LEARN MORE</a
                  >
                  <!-- <router-link
                    :to="post['section2-learn-more']"
                    class="underline-text secondary normal-text"
                  >
                    LEARN MORE</router-link
                  > -->
                </div>
                <div class="col-lg-4 more-posts sm-hide col-md-4-5">
                  <div class="d-flex flex-column px-lg-5 align-items-start">
                    <p class="text-white md-sm-sub-title">
                      <span v-html="post['section2-link2-title']"></span>
                    </p>
                    <div class="bg-divider my-lg-5 my-md-3"></div>
                    <div class="w-100">
                      <p class="normal-text mb-2 text-white">
                        Want to receive our business intelligence directly?
                      </p>
                      <p class="normal-text mb-3 text-white">
                        SIGN UP TO OUR MAILING LIST
                        <router-link
                          to="/contact-us"
                          class="secondary normal-text underline-text"
                        >
                          HERE
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 lg-hide md-hide">
              <div class="bg-divider mx-0 my-md-5 my-sm-5"></div>
            </div> -->

            <div class="more-posts col-12 lg-hide md-hide w-100">
              <div class="d-flex flex-column align-items-start">
                <div class="bg-divider my-5"></div>
                <!-- <router-link
                  :to="post['section2-link2']"
                  class="secondary normal-text underline-text"
                >
                  <span v-html="post['section2-link2-title']"></span>
                </router-link> -->
                <p class="sub-title text-white md-sm-sub-title">
                  <span v-html="post['section2-link2-title']"></span>
                </p>
                <div class="bg-divider my-3"></div>
                <div class="w-100">
                  <p class="normal-text mb-2 text-white">
                    Want to receive our business intelligence directly?
                  </p>
                  <router-link
                    to="/contact-us"
                    class="secondary normal-text mb-3 underline-text"
                  >
                    SIGN UP TO OUR MAILING LIST HERE
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <newFeeds />
        </div> -->
      </div>
    </div>
    <slider
      id="home-section-3"
      v-toggle-header="{
        header: 'dark-header-home-section-3',
        logo: 'light-logo-home-section-3',
      }"
      :sliderType="'home_slider'"
    />
    <Footer
      :data="[
        {
          name: post['footer-link1-title'],
          url: post['footer-link1'],
        },
        {
          name: post['footer-link2-title'],
          url: post['footer-link2'],
        },
      ]"
    />
  </div>
</template>

<script>
import slider from "../common/slider.vue";
// import newFeeds from "../common/newFeeds.vue";
import Footer from "../common/footer.vue";
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Home",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      post: [],
      getting: true,
    };
  },
  created() {
    this.getHomePost();
  },
  components: {
    slider,
    // newFeeds,
    Footer,
  },
  methods: {
    getHomePost() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("home");
      if (cacheData) {
        this.post = cacheData[0];
        this.$store.commit("setHomeHeader", {
          english: this.post["header-english"],
          myanmar: this.post["header-myanmar"],
          background: this.post["header-background"],
        });
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("home").then((data) => {
        this.post = data[0];
        this.$store.commit("setHomeHeader", {
          english: this.post["header-english"],
          myanmar: this.post["header-myanmar"],
          background: this.post["header-background"],
        });
        this.getting = false;
        this.$store.commit("getData");
      });
    },
  },
};
</script>
<style scoped>
.s2-img {
  max-height: 250px;
  object-fit: cover;
}
</style>


