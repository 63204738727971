export default {
  isLoggedIn: (state) => !!state.user,
  isLg: () => {
    return window.matchMedia("(min-width: 1200px)").matches;
  },
  isMd: () => {
    return window.matchMedia("(min-width: 768px) and (max-width: 1200px)")
      .matches;
  },
  isSm: () => {
    return window.matchMedia("(max-width: 767px)").matches;
  },
  filterFacts: (state) => (type) => {
    var filtered = state.facts.filter((fact) => fact.page == type);
    filtered.sort((a, b) => a.id - b.id);
    return filtered;
  },
  filterAccordion: (state) => (type) => {
    var filtered = state.accordion.filter((fact) => fact.page == type);
    filtered.sort((a, b) => a.id - b.id);
    return filtered;
  },
};
