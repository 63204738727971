import { createApp } from "vue";
import App from "./App.vue";
import router from "./core/router";
import store from "./core/store";
import counter from "./core/helper/scroll-counter/index";
import scrollAni from "./core/helper/scroll-ani/index";
import parallex from "./core/helper/parallex/index";
import cardHidden from "./core/helper/cardHidden/index";
import setMapHeight from "./core/helper/setMapHeight/index";
import header from "./core/helper/header/index";
import { createMetaManager } from "vue-meta";
import { appUrl } from "./core/constant/data";
import axios from "./core/axios";
import get from "./core/axios/get";

import moment from "moment";
import VueEasyLightbox from "vue-easy-lightbox";
import VueLazyLoad from "vue3-lazyload";
import "./assets/style/index.css";
import "@vueform/toggle/themes/default.css";
import "vueperslides/dist/vueperslides.css";

const loader = "/image/loader.svg";
const error = "/image/error.svg";

import Vue3TouchEvents from "vue3-touch-events";
import VueNextSelect from "vue-next-select";

const app = createApp(App, {
  created() {},
});
app.use(router);
app.use(store);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$get = get;
app.config.globalProperties.$moment = moment;

app.use(VueEasyLightbox);
app.use(counter);
app.use(parallex);
app.use(scrollAni);
app.use(cardHidden);
app.use(setMapHeight);
app.use(header);
app.component("v-select", VueNextSelect);

app.use(createMetaManager());
app.use(VueLazyLoad, {
  error: appUrl + error,
  loading: appUrl + loader,
});
app.use(Vue3TouchEvents);

router.isReady().then(() => {
  app.mount("#app");
});
