<template>
  <div class="row">
    <div class="col-12 vh-100 px-0" v-if="getting">
      <svg
        role="img"
        width="460"
        height="460"
        class="vh-100 vw-100"
        aria-labelledby="loading-aria"
        viewBox="0 0 400 460"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clip-path="url(#clip-path)"
          style="fill: url('#fill')"
        ></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="0" y="-17" rx="2" ry="2" width="400" height="476" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stop-color="#e0e0e0" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="1.59996" stop-color="#f0f0f0" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="2.59996" stop-color="#e0e0e0" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="col-12 vh-100 px-0" v-if="!getting">
      <iframe
        class="map-view vh-100 vw-100"
        v-if="!showError"
        :src="map"
      ></iframe>
      <div class="map-view vh-100 vw-100 flex-center" v-else>
        <h1>You are not allowed to accesst this map!</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Map",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      map: "",
      getting: true,
      showError: false,
    };
  },
  created() {
    this.getMap();
  },
  methods: {
    getMap() {
      this.getting = true;
      var uid = document.cookie
        .split("; ")
        .find((row) => row.startsWith("uid="));
      if (uid) {
        uid = uid.split("=")[1];
      }
      this.$axios
        .get("/map/" + this.$route.params.slug + "/" + uid)
        .then((res) => {
          this.map = res.data;
          this.getting = false;
          this.showError = false;
        })
        .catch((err) => {
          if (err.response.data.data.status == 401) {
            this.showError = true;
          }
          this.getting = false;
        });
    },
  },
  beforeUnmount() {
    const check = document.getElementById("map-script");
    if (check) {
      check.remove();
    }
  },
};
</script>

<style scoped>
.map {
  height: unset !important;
}
</style>