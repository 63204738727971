<template>
  <div class="col-lg-12 email pt-0 bg-padding">
    <div class="row">
      <transition name="fade">
        <div class="noti" :key="message" v-if="showNoti">
          <p v-html="message"></p>
        </div>
      </transition>

      <div class="col-lg-12 col-md-10" v-if="!success">
        <p class="sub-title md-title sm-email-title">
          Want to receive our business intelligence directly?
        </p>
        <p class="sm-text secondary mb-5 mb-sm-4">Please fill out this form.</p>
        <form ref="form" class="email-form" @submit.prevent="sendEmail">
          <input required type="text" name="your-name" placeholder="Name" />
          <input required type="text" name="your-job" placeholder="Job Title" />
          <input
            required
            type="text"
            name="your-organisation"
            placeholder="Organisation"
          />
          <input required type="email" name="your-email" placeholder="Email" />
          <input
            required
            type="number"
            name="your-number"
            placeholder="Contact number"
          />
          <div
            class="g-recaptcha"
            data-sitekey="6LepJJkcAAAAAK6kK17NFIms8t5-VGH3bytOLS-s"
          ></div>

          <button
            type="submit"
            class="button secondary-bg mt-md-4 text-white normal-text"
          >
            <p v-if="!sending">Send</p>

            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              stroke="white"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              class="loading-icon"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="2" x2="12" y2="6"></line>
              <line x1="12" y1="18" x2="12" y2="22"></line>
              <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
              <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
              <line x1="2" y1="12" x2="6" y2="12"></line>
              <line x1="18" y1="12" x2="22" y2="12"></line>
              <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
              <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
          </button>
        </form>
      </div>

      <div class="col-lg-12 col-md-10 flex-center" v-else>
        <div class="flex-align-center my-5">
          <svg
            width="70px"
            class="mx-3"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.3885 30.3672C53.643 16.4552 44.2092 3.34693 30.3175 1.0891C16.4258 -1.16873 3.33672 8.27885 1.08219 22.1909C-1.17234 36.1029 8.26144 49.2112 22.1531 51.469C36.0448 53.7269 49.1339 44.2793 51.3885 30.3672Z"
              fill="#DC5F40"
            />
            <path
              d="M15.7656 26.6806L23.0078 33.1275L36.6876 19.4277"
              stroke="white"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </svg>

          <p class="sm-sub-title">
            You have successfully signed up to receive regular updates from us.
            Thank you for your support!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sending: false,
      showNoti: false,
      message: "",
      success: false,
    };
  },
  created() {
    var script = document.createElement("script");
    script.id = "google-recaptcha-script";
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  },
  beforeUnmount() {
    const check = document.getElementById("google-recaptcha-script");
    if (check) {
      check.remove();
    }
  },
  methods: {
    sendEmail() {
      this.sending = true;
      const formData = new FormData(this.$refs.form);
      this.$axios
        .post(
          "https://smartpowermyanmar.org/new/wp-json/contact-form-7/v1/contact-forms/401/feedback",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(
          (res) => {
            if (res.data.status !== "mail_failed") {
              this.showNoti = true;
              this.message = res.data.message;
              this.$refs.form.reset();
              this.success = true;
              setTimeout(() => {
                this.showNoti = !this.showNoti;
              }, 5000);
              this.sending = false;
            } else {
              this.showNoti = true;
              this.message = "Error : " + res.data.message;
              setTimeout(() => {
                this.showNoti = !this.showNoti;
              }, 5000);
              this.sending = false;
            }
          },
          (error) => {
            this.showNoti = true;
            this.message = "Error : " + error.text;
            setTimeout(() => {
              this.showNoti = !this.showNoti;
            }, 5000);
            this.sending = false;
          }
        );
    },
  },
};
</script>

<style scoped>
form.email-form {
  display: flex;
  flex-direction: column;
}
input {
  height: 38px;
  margin-bottom: 15px;
  padding: 0px 20px;
  background: transparent;
  border: 1px solid rgb(var(--secondary));
  border-radius: 0px !important;
  box-shadow: 0px !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(var(--text));
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(var(--text));
}

input::placeholder {
  color: rgb(var(--text));
}
.button {
  background: rgb(var(--secondary));
  color: #fff !important;
  width: 100%;
  margin-top: 38px;
  height: 38px;
}
.button:active {
  background: rgb(var(--secondary), 0.7);
  color: #fff !important;
}
</style>