<template>
  <div
    class="top-border-primary mt-5 bg-m mx-0 mb-0 mb-2"
    :style="`border-color:${type.color}`"
    :id="type.slug"
    v-if="!hideBorder && (data.data.length > 0 || (type.has_child && !noItem))"
  ></div>
  <div
    class="row recent-bussiness-box mb-md-5 pb-md-3 pb-0 px-0"
    v-if="!hasFeeds && type.children.length == 0"
    :class="hideBorder && 'mt-5'"
  >
    <div class="col-lg-12">
      <p
        class="title md-sm-title mb-sm-2"
        :style="`color:${type.color}`"
        v-if="data.data.length > 0"
      >
        <span v-html="type.name"></span>
      </p>
      <div class="row" v-if="!getting">
        <div
          class="col-lg-3 col-md-6 mb-0 mt-md-5 bg-m mx-0"
          v-for="item in data.data"
          :key="item.ID"
        >
          <businessCardPost :post="item" :key="item.ID" :color="type.color" />
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-3 col-md-6 loader-box" v-for="i in 4" :key="i">
          <img src="/image/loader.svg" class="w-100" alt="" />
        </div>
      </div>

      <div class="w-100 mt-5" v-if="hasNext">
        <button
          @click="loadMore"
          :style="'border-color:' + type.color + ';color:' + type.color"
          class="input load-more normal-text"
        >
          <p v-if="!loading">Read more</p>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            :stroke="type.color"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            class="loading-icon"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div
    class="row recent-bussiness-box mb-md-5 pb-md-3 pb-0 px-0"
    v-if="hasFeeds"
  >
    <div class="col-lg-12 col-md-12">
      <p
        class="title md-sm-title mb-sm-2"
        :style="`color:${type.color}`"
        v-if="data.data.length > 0"
      >
        <span v-html="type.name"></span>
      </p>
      <div class="row" v-if="!getting">
        <div
          class="col-lg-3 col-md-6 mb-0 mt-md-5 bg-m mx-0"
          v-for="item in data.data"
          :key="item.ID"
        >
          <businessCardPost :post="item" :key="item.ID" :color="type.color" />
        </div>
        <div class="col-lg-3 col-md-6 mb-0 mb-sm-3 mt-md-5 bg-m mx-0">
          <div class="news-box py-3">
            <p class="mb-2 sm-text">
              Want to receive our business intelligence directly?
            </p>
            <router-link to="/contact-us" class="secondary mb-3 underline">
              SIGN UP TO OUR MAILING LIST HERE
            </router-link>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-3 col-md-12 loader-box" v-for="i in 12" :key="i">
          <img src="/image/loader.svg" class="w-100" alt="" />
        </div>
      </div>
      <div class="w-100 flex-center mt-5 mb-3" v-if="hasNext">
        <button
          @click="loadMore"
          class="input load-more secondary-bg text-white normal-text"
        >
          <p v-if="!loading">Read more</p>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            stroke="white"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            class="loading-icon"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div
    class="row recent-bussiness-box mb-md-5 pb-md-3 pb-0 px-0"
    v-if="!hasFeeds && type && type.has_child"
    v-show="!noItem"
  >
    <div class="col-lg-12">
      <p class="title md-sm-title mb-sm-2" :style="`color:${type.color}`">
        <span v-html="type.name"></span>
      </p>
      <p class="mt-2 mt-md-3 post-detail" style="width: 50%">
        <span v-html="type.description"></span>
      </p>
      <childBusiness
        :type="childType"
        v-for="childType in type.children"
        :key="childType.term_id"
        @updatedData="check"
      />
    </div>
  </div>
</template>

<script>
import businessCardPost from "./businessCardPost.vue";
import childBusiness from "./childBusiness.vue";

export default {
  props: {
    type: {},
    hasFeeds: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        data: [],
        max_pages: 0,
        current_page: 1,
      },
      loading: false,
      getting: true,
      child1: [],
      child2: [],
      checkList: [],
    };
  },
  components: {
    businessCardPost,
    childBusiness,
  },
  watch: {},
  mounted() {
    this.getBusiness();
  },
  computed: {
    noItem() {
      return this.checkList.every((data) => !data);
    },
    hasNext() {
      return parseInt(this.data.max_pages) > parseInt(this.data.current_page);
    },
  },
  methods: {
    check(val) {
      this.checkList.push(val);
    },
    getBusiness() {
      this.getting = true;
      if (this.type && !this.type.has_child) {
        var per_page = 8;
        var url =
          (url = `/bussiness-intelligence/${this.type.term_id}/?paged=${this.data.current_page}&per_page=${per_page}`);
        if (this.$route.query.tag) {
          url = `/bussiness-intelligence/${this.type.term_id}/?paged=${this.data.current_page}&tag=${this.$route.query.tag}&per_page=${per_page}`;
        }
        const cacheData = this.$get.getCachedData(url);
        if (cacheData && cacheData.data) {
          this.data = cacheData;
          this.getting = false;
        }
        this.$get.getFreshData(url).then((data) => {
          if (data.data) {
            this.data = data;
          }
          this.getting = false;
        });
      }
    },
    loadMore() {
      if (this.type && !this.type.has_child) {
        this.loading = true;
        var url = (url = `/bussiness-intelligence/${this.type.term_id}/?paged=${
          parseInt(this.data.current_page) + 1
        }&per_page=8`);
        if (this.$route.query.tag) {
          url = `/bussiness-intelligence/${this.type.term_id}/?paged=${
            parseInt(this.data.current_page) + 1
          }&tag=${this.$route.query.tag}&per_page=8`;
        }
        this.$axios
          .get(url)
          .then((res) => {
            this.data.data.push(...res.data.data);
            this.data.current_page = res.data.current_page;
            this.data.max_pages = res.data.max_pages;
            this.loading = false;
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
button.input.load-more {
  height: 38px;
  width: 180px !important;
}
.loader-box {
  height: 150px;
  margin: 30px 0px;
  overflow: hidden;
}
.news-box {
  border-left: 1px solid rgb(var(--primary));
  padding-left: 20px;
}
/* ------------------------------------------------"desktop"------------------------------------------------ */
@media (min-width: 992px) {
  .recent-bussiness-box .col-lg-5 {
    max-width: 38% !important;
  }
  .recent-bussiness-box .col-lg-2 {
    max-width: 24% !important;
    flex: 0 0 24%;
  }
}
</style>