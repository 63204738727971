import ScrollAnimate from "./scroll-animate";

export default {
  install(Vue) {
    Vue.directive("counter", {
      mounted(el) {
        const scrollAnimate = ScrollAnimate();
        var str = el.textContent.split(/([0-9]+)/);
        const value = parseInt(str[1]);
        const duration = 1000;
        const prefix = str[0];
        const surfix = str[2];
        setTimeout(() => {
          if (scrollAnimate.isInView(el.getBoundingClientRect())) {
            scrollAnimate.run(el, value, duration, prefix, surfix);
          }
        }, 200);

        // let lastScrollTop = window.pageYOffset;
        window.addEventListener(
          "scroll",
          function() {
            scrollAnimate.run(el, value, duration, prefix, surfix);
          },
          false
        );
      },
    });
  },
};
