<template>
  <div class="col-lg-12 testimonial bg-padding">
    <div class="secondary-border mt-0 mt-md-5 mb-sm-2 top-border-primary"></div>
    <p class="lg-title primary">Testimonials</p>
    <div class="row sm-overflow-hidden">
      <div class="col-12" v-if="!getting" v-touch:swipe="onSwipe">
        <vueper-slides
          ref="testiSlides"
          class="no-shadow lg-hide"
          :breakpoints="breakpoints"
          slide-multiple
          :visible-slides="2"
          :gap="5"
          :autoplay="false"
          :arrows-outside="false"
          :touchable="false"
        >
          <vueper-slide
            v-for="item in testimonials"
            :title="item.id.toString()"
            :key="item.id"
          >
            <template #content>
              <div class="vueperslide__content-wrapper">
                <testimonial :item="item" />
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>

        <div class="row sm-hide md-hide">
          <div class="col-lg-6" v-for="item in testimonials" :key="item.id">
            <testimonial :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import testimonial from "./testimonial.vue";

export default {
  props: {
    page: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      getting: true,
      testimonials: [],
      breakpoints: {
        1200: {
          visibleSlides: 2,
        },
        900: {
          visibleSlides: 1,
        },
        600: {
          visibleSlides: 1,
        },
      },
    };
  },
  components: { VueperSlides, VueperSlide, testimonial },
  created() {
    this.getTestimonials();
  },
  methods: {
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.testiSlides.previous();
      } else if (direction === "left") {
        this.$refs.testiSlides.next();
      }
    },
    getTestimonials() {
      this.getting = true;

      const cacheData = this.$get.getCachedData("testimonials");
      if (cacheData) {
        this.testimonials = cacheData.filter(
          (data) =>
            data["show-page"].filter((page) => page == this.page).length > 0
        );
        this.getting = false;
      }
      this.$get.getFreshData("testimonials").then((res) => {
        this.testimonials = res.filter(
          (data) =>
            data["show-page"].filter((page) => page == this.page).length > 0
        );
        this.getting = false;
      });
    },
  },
};
</script>

<style >
.testimonial
  .vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  height: unset !important;
  margin: auto;
}
.testimonial button.vueperslides__arrow {
  color: rgb(var(--primary), 0.6);
}
.testimonial .vueperslides__bullet {
  margin-bottom: 0px !important;
}

.testimonial .vueperslides__bullet .default {
  border: 0 !important;
  background: rgb(var(--text), 0.1);
  box-shadow: unset !important;
}
.testimonial .vueperslides__bullet--active .default {
  background: rgb(var(--text), 0.3) !important;
}
/* ------------------------------------------------'tablet'------------------------------------------------ */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .testimonial .vueperslides__arrow--prev {
    right: auto;
    left: -20px;
  }
  .testimonial .vueperslides__arrow--next {
    left: auto;
    right: -20px;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial .vueperslides__arrow--prev {
    right: auto;
    left: -45px;
  }
  .testimonial .vueperslides__arrow--next {
    left: auto;
    right: -45px;
  }
}
@media screen and (max-width: 767px) {
  .testimonial .vueperslides__arrow--prev {
    display: none;
  }
  .testimonial .vueperslides__arrow--next {
    display: none;
  }
}
.testimonial .vueperslides__track {
  position: relative !important;
}
.testimonial .vueperslides__parallax-wrapper {
  padding-bottom: 0% !important;
}
</style>