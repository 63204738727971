import store from "../../store";
export default {
  data() {
    return {
      windowWidth : document.documentElement.clientWidth,
      windowHeight : document.documentElement.clientHeight,
    }
  },
  mounted() {

    window.addEventListener('resize', this.setDimensions);
  },
  methods: {
    setDimensions() {
        this.windowWidth = document.documentElement.clientWidth;
        this.windowHeight = document.documentElement.clientHeight;
    },
    beforeDestroy() {
    window.removeEventListener('resize', this.setDimensions);
    },
  }, 
  
  install(Vue) {
    Vue.directive("cardHidden", (el) => {
      var val;
      if (store.getters.isSm) {
        val = el.children[0].clientHeight + 7;
      } else {
        val = el.children[0].clientHeight + 15;
      }
      el.style.transform = `translateY(${el.clientHeight - val}px)`;
    });
  },
};
