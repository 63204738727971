<template>
	<div class="col-lg-12 pt-md-0 white-bg bg-padding">
		<div class="row px-sm-2">
			<div class="col-12 px-sm-2">
				<div class="top-border-primary mt-0 my-md-5 mb-4"></div>
			</div>
			<div class="col-lg-6 col-md-6 col-sm-6 px-sm-2">
				<router-link :to="data[0].url" class="lg-button primary-bg"
					><span class="footer-link" v-html="titleCase(data[0].name)"></span
				></router-link>
			</div>
			<div class="col-lg-6 col-md-6 col-sm-6 px-sm-2 right-block">
				<router-link :to="data[1].url" class="lg-button flex-end primary-bg">
					<span class="footer-link" v-html="titleCase(data[1].name)"></span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {},
		},
		methods: {
			titleCase(str) {
				var splitStr = str.toLowerCase().split(' ');
				for (var i = 0; i < splitStr.length; i++) {
					// You do not need to check if i is larger than splitStr length, as your for does that for you
					// Assign it back to the array
					splitStr[i] =
						splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
				}
				// Directly return the joined string
				return splitStr.join(' ');
			},
		},
	};
</script>

<style>
	.footer-link::first-letter {
		text-transform: uppercase !important;
	}
	@media screen and (max-width: 767px) {
		span.footer-link {
			text-align: center;
		}
	}
	@media screen and (max-width: 400px) {
		span.footer-link {
			font-size: 0.8rem;
		}
	}
</style>
