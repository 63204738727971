<template>
  <div class="row" v-if="!getting">
    <div class="header-height"></div>
    <div class="col-lg-12 bg-padding">
      <div class="row">
        <div class="col-lg-3 mb-sm-5 col-md-3">
          <p class="lg-title secondary">
            <span v-html="post['section1-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 col-md-6">
          <p class="sm-sub-title mb-3">
            <span v-html="post['section1-p1']"></span>
          </p>
          <p class="sm-sub-title secondary">
            <span v-html="post['section2-p2']"></span>
          </p>
        </div>
        <div class="col-lg-3 my-sm-5 col-md-3 d-flex position-relative" v-touch:swipe="onSwipe">
          <div v-scroll-ani class="scroll-ani top">
            <svg width="16" height="16" class="scroll-ani-circle" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="7" stroke="rgb(var(--text))" stroke-width="2" />
            </svg>

            <svg width="2" height="90" class="scroll-ani-line" viewBox="0 0 2 100" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <line x1="1" y1="4.37114e-08" x2="0.999996" y2="100" stroke="rgb(var(--text))" stroke-width="2" />
            </svg>
          </div>

          <vueper-slides :touchable="false" class="w-100 dark-dots no-shadow facts-slide" ref="integrated" :arrows="false"
            :autoplay="true" :dragging-distance="50">
            <vueper-slide v-for="item in filterFacts($route.name)" :title="item.title" :key="item.title">
              <template #content>
                <div class="vueperslide__content-wrapper">
                  <div class="ml-4 fact" :key="item.item">
                    <p class="title">
                      <span v-html="item.title"></span>
                    </p>
                    <p class="normal-text">
                      <span v-html="item._content"></span>
                    </p>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pt-0 mt-md-4 bg-padding">
      <div class="top-border-primary mb-sm-2"></div>
      <p class="lg-title">
        <span v-html="post['section2-title']"></span>
      </p>
      <div class="row bg-padding user-lists mt-md-3 pb-0 px-0">
        <div class="col-lg-6">
          <img :src="post['map-featured-image'].url" class="w-100" alt="" />
          <p class="sm-sub-title text-md-center mt-4 mb-2">
            <span v-html="post['login-note']"></span>
          </p>
          <router-link to="/login" class="button w-100 secondary-bg">
            <p class="sm-sub-title text-white">
              <span v-html="post['login-button']"></span>
            </p>
          </router-link>
        </div>
        <div class="col-lg-6 pt-0 mt-5 text-md-center bg-padding">
          <p class="lg-title mb-2 md-title sm-sm-title">
            <span v-html="post['section2-subtitle']"></span>
          </p>
          <router-link :to="post['demo-link']" class="sub-title mb-4 secondary underline text-bold">
            <span v-html="post['demo-text']"></span>
          </router-link>
          <p class="sm-sub-title">
            <span v-html="post['contact-us-note']"></span><br />
            <a :href="`mailto:${post['email']}`" class="secondary">
              <span v-html="post['email']"></span></a>
          </p>
        </div>
      </div>
    </div>

    <div class="col-lg-12 pt-0 pb-0 bg-padding mb-sm-3">
      <div class="top-border-primary mb-sm-2"></div>
      <p class="title"><span v-html="post['section3-title']"></span></p>
      <div class="row bg-padding user-lists px-md-2 pb-0 px-0 px-sm-2">
        <div class="col-lg-5ths col-md-5ths px-md-2 col-sm-6 mb-sm-3 px-sm-2" v-for="user in post.users" :key="user.name">
          <div class="user-box card-hidden-box">
            <img class="w-100" v-lazy="user.image.url" alt="" />
            <div class="user-box-content p-3 card-hidden" v-cardHidden="47">
              <p class="normal-text user-title-text pb-2 text-white">
                <span v-html="user.label"></span>
              </p>
              <div class="user-border"></div>
              <p class="sm-text text-white">
                <span v-html="user.des"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <testiSlide page="integrated-electrification" />
    <recentBusiness tag="Integrated Electrification" />
    <Footer :data="[
      {
        name: post['footer-link-1-title'],
        url: post['footer-link-1'],
      },
      {
        name: post['footer-link-2-title'],
        url: post['footer-link-2'],
      },
    ]" />
  </div>
</template>

<script>
import recentBusiness from "../common/recentBusiness.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import Footer from "../common/footer.vue";
import testiSlide from "../common/testiSlide.vue";
import { mapGetters } from "vuex";

import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Integrated Electrification",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  computed: {
    ...mapGetters(["isLg", "filterFacts"]),
  },
  data() {
    return {
      getting: true,
      post: [],
    };
  },
  components: {
    recentBusiness,
    Footer,
    testiSlide,
    VueperSlides,
    VueperSlide,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("integrated-electrifi");
      if (cacheData) {
        this.post = cacheData[0];
        Object.keys(this.post.users).forEach((key) => {
          this.$store.commit("preloadImage", this.post.users[key].image.url);
        });
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("integrated-electrifi").then((data) => {
        this.post = data[0];
        Object.keys(this.post.users).forEach((key) => {
          this.$store.commit("preloadImage", this.post.users[key].image.url);
        });
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.integrated.previous();
      } else if (direction === "left") {
        this.$refs.integrated.next();
      }
    },
  },
};
</script>

<style scoped>
.card-hidden-box:hover .card-hidden {
  transform: translateY(0px) !important;
}

.user-box-content {
  background: rgba(13, 46, 74, 0.6);
  backdrop-filter: blur(10px);
}

.user-border {
  border-bottom: 1px solid transparent;
  margin-bottom: 10px;
  transition: 0.3s all ease-in-out;
}

.user-box:hover .user-border {
  border-color: rgb(var(--border)) !important;
}

.user-border {
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;
}

.user-box.card-hidden-box {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  height: 370px;
}

.card-hidden-box img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}

.user-box-content.p-3.card-hidden {
  height: 150px;
}
</style>