<template>
  <div class="main-menu-items">
    <div class="row">
      <div class="col-lg-3 menu-col col-md-3 flex-column flex-align-start">
        <div class="flex-align-center menu-title-header w-100">
          <p
            class="
              sm-sub-title
              md-sm-sub-title
              menu-title
              underline-text
              text-bold
              mb-5
            "
            style="color: #666666"
          >
            ABOUT US
          </p>
        </div>
        <router-link
          to="/who-we-are"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >Who We Are</router-link
        >
        <router-link
          to="/who-we-are#our-team"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >Our Team</router-link
        >
        <router-link
          :to="{ name: 'Who We Are', hash: '#our-partners' }"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >OUR PARTNERS</router-link
        >
        <router-link
          to="/who-we-are#our-backstory"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >Our Backstory</router-link
        >
      </div>
      <div class="col-lg-3 menu-col col-md-3 flex-column flex-align-start">
        <div class="flex-align-center w-100 menu-title-header">
          <p
            class="
              sm-sub-title
              md-sm-sub-title
              underline-text
              menu-title
              text-bold
              mb-5
            "
            style="color: #666666"
          >
            OUR STRATEGIES
          </p>
        </div>
        <router-link
          to="/innovative-finance"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >INNOVATIVE FINANCE</router-link
        >
        <router-link
          to="/decentralised-renewable-solutions"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >DECENTRALISED RENEWABLE SOLUTIONS</router-link
        >
        <router-link
          to="/integrated-electrification"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >INTEGRATED ELECTRIFICATION
        </router-link>
        <router-link
          to="/login"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >Myanmar Power Map
        </router-link>
      </div>
      <div class="col-lg-3 menu-col col-md-3 flex-column flex-align-start">
        <div class="flex-align-center w-100 menu-title-header">
          <p
            class="
              sm-sub-title
              md-sm-sub-title
              underline-text
              menu-title
              text-bold
              mb-5
            "
            style="color: #666666"
          >
            OUR BUSINESS INTELLIGENCE
          </p>
        </div>
        <router-link
          to="/business-intelligence#reports"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >REPORTS</router-link
        >
        <router-link
          to="/business-intelligence#technical-briefs"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >TECHNICAL BRIEFS</router-link
        >
        <router-link
          to="/business-intelligence#articles-case-studies-more"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >ARTICLE, CASE STUDIES & MORE</router-link
        >
        <router-link
          to="/business-intelligence#myanmar-electrification-library"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >MYANMAR ELECTRIFICATION LIBRARY</router-link
        >
      </div>
      <div class="col-lg-3 menu-col col-md-3 flex-column flex-align-start">
        <div class="flex-align-center menu-title-header w-100">
          <p
            class="
              sm-sub-title
              md-sm-sub-title
              underline-text
              menu-title
              text-bold
              mb-5
            "
            style="color: #666666"
          >
            CONTACT
          </p>
        </div>
        <router-link
          to="/contact-us"
          class="text-white underline-btn sm-text mb-4 text-uppercase"
          >SEND US A MESSAGE</router-link
        >
        <div class="flex-align-center sm-hide social-menu">
          <a href="https://www.facebook.com/smartpowermyanmar" target="_blank">
            <svg
              width="42"
              height="42"
              class="mr-3 menu-svg"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.2891 20.645C41.2891 9.24186 32.0474 0 20.6445 0C9.24165 0 0 9.24186 0 20.645C0 32.0481 9.24165 41.29 20.6445 41.29C20.7655 41.29 20.8865 41.29 21.0074 41.2819V25.2175H16.5721V20.0482H21.0074V16.2418C21.0074 11.8306 23.7009 9.42735 27.6363 9.42735C29.5233 9.42735 31.1442 9.56444 31.6119 9.62896V14.2418H28.9023C26.7653 14.2418 26.346 15.2579 26.346 16.7499V20.0402H31.4668L30.7974 25.2095H26.346V40.4916C34.9747 38.0158 41.2891 30.0723 41.2891 20.645Z"
                fill="#DC5F40"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/smart-power-myanmar/"
            target="_blank"
          >
            <svg
              width="43"
              height="42"
              viewBox="0 0 43 42"
              class="menu-svg"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5273 0C10.1274 0 0.882812 9.24482 0.882812 20.645C0.882812 32.0452 10.1274 41.29 21.5273 41.29C32.9273 41.29 42.1719 32.0452 42.1719 20.645C42.1719 9.24482 32.9273 0 21.5273 0ZM15.5283 31.2094H10.5004V16.0826H15.5283V31.2094ZM13.0145 14.017H12.9817C11.2945 14.017 10.2033 12.8556 10.2033 11.404C10.2033 9.91959 11.3279 8.79025 13.0479 8.79025C14.7678 8.79025 15.8263 9.91959 15.859 11.404C15.859 12.8556 14.7678 14.017 13.0145 14.017ZM33.6578 31.2094H28.6305V23.1169C28.6305 21.0832 27.9025 19.6962 26.0833 19.6962C24.6945 19.6962 23.8672 20.6318 23.5037 21.5349C23.3708 21.8581 23.3383 22.3099 23.3383 22.7619V31.2094H18.3108C18.3108 31.2094 18.3766 17.5018 18.3108 16.0826H23.3383V18.2244C24.0065 17.1937 25.2019 15.7276 27.8695 15.7276C31.1774 15.7276 33.6578 17.8895 33.6578 22.5357V31.2094Z"
                fill="#DC5F40"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="col-lg-12 mt-md-5 menu-footer bg-padding text-center">
        <div class="flex-center lg-hide pb-4 md-hide">
          <a href="https://www.facebook.com/smartpowermyanmar" target="_blank">
            <svg
              width="55"
              height="55"
              class="mr-3"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.2891 20.645C41.2891 9.24186 32.0474 0 20.6445 0C9.24165 0 0 9.24186 0 20.645C0 32.0481 9.24165 41.29 20.6445 41.29C20.7655 41.29 20.8865 41.29 21.0074 41.2819V25.2175H16.5721V20.0482H21.0074V16.2418C21.0074 11.8306 23.7009 9.42735 27.6363 9.42735C29.5233 9.42735 31.1442 9.56444 31.6119 9.62896V14.2418H28.9023C26.7653 14.2418 26.346 15.2579 26.346 16.7499V20.0402H31.4668L30.7974 25.2095H26.346V40.4916C34.9747 38.0158 41.2891 30.0723 41.2891 20.645Z"
                fill="#DC5F40"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/smart-power-myanmar/"
            target="_blank"
          >
            <svg
              width="55"
              height="55"
              viewBox="0 0 43 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5273 0C10.1274 0 0.882812 9.24482 0.882812 20.645C0.882812 32.0452 10.1274 41.29 21.5273 41.29C32.9273 41.29 42.1719 32.0452 42.1719 20.645C42.1719 9.24482 32.9273 0 21.5273 0ZM15.5283 31.2094H10.5004V16.0826H15.5283V31.2094ZM13.0145 14.017H12.9817C11.2945 14.017 10.2033 12.8556 10.2033 11.404C10.2033 9.91959 11.3279 8.79025 13.0479 8.79025C14.7678 8.79025 15.8263 9.91959 15.859 11.404C15.859 12.8556 14.7678 14.017 13.0145 14.017ZM33.6578 31.2094H28.6305V23.1169C28.6305 21.0832 27.9025 19.6962 26.0833 19.6962C24.6945 19.6962 23.8672 20.6318 23.5037 21.5349C23.3708 21.8581 23.3383 22.3099 23.3383 22.7619V31.2094H18.3108C18.3108 31.2094 18.3766 17.5018 18.3108 16.0826H23.3383V18.2244C24.0065 17.1937 25.2019 15.7276 27.8695 15.7276C31.1774 15.7276 33.6578 17.8895 33.6578 22.5357V31.2094Z"
                fill="#DC5F40"
              />
            </svg>
          </a>
        </div>

        <p class="sm-text mt-5 mt-sm-2 text-white">
          © {{currentYear}} Smart Power Myanmar. All Rights Reserved.<br />
          Smart Power Myanmar is supported by The Rockefeller Foundation and
          managed by Pact.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.main-menu-items {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: calc(var(--bg-padding) * 2);

  height: 100vh;
  display: flex;
  align-items: center;
}
</style>