<template>
  <div class="row" v-if="!getting">
    <!-- //fix lag on scroll -->
    <div
      class="fix-bg"
      :style="`background:url(${post['banner-background'].url}) no-repeat`"
    ></div>
    <div class="col-lg-12 who-we-are-section-1 bg-padding" style="z-index: 1">
      <div class="header-height"></div>

      <div class="row">
        <div class="col-lg-12">
          <p class="lg-title secondary mb-3 mb-sm-5 text">
            <span v-html="post['section1-title']"></span>
          </p>
        </div>
        <div
          class="
            col-lg-9 col-md-8
            bg-padding
            py-0
            mb-sm-5
            pb-sm-5
            pl-lg-3 pl-md-3
          "
        >
          <!-- <Toggle
						v-model="isMM"
						style="margin-top: 10px"
						:onLabel="'MM'"
						:offLabel="'EN'"
					/> -->
          <div class="w-100 position-relative" :style="'height:' + dyHeight">
            <transition name="fade">
              <p
                class="
                  text-white
                  position-absolute
                  lang-text
                  sub-title
                  sm-sub-title
                  my-0
                "
                v-if="!isMM"
                style="line-height: 1.7 !important"
              >
                <span v-html="post['section1-english-content']"></span>
              </p>
              <!-- <p
								class="
                  text-white
                  lang-text
                  sub-title
                  position-absolute
                  sm-sub-title
                  my-0
                "
								v-else
								style="line-height: 1.7 !important"
							> -->
              <!-- <span
                  class="padauk"
                  v-html="post['section1-myanmar-content']"
                ></span> -->
              <!-- <span class="padauk">
									SPM သည် ဆန်းသစ်သော စွမ်းအင်အသုံးပြုနည်းလမ်းများကိုမျှဝေပေးကာ
									လူနေမှုဘဝတိုးတက်စေခြင်း၊ အခွင့်အလမ်းသစ်များ ချိတ်ဆက်ပေးခြင်း
									နှင့် ရင်းနှီးမြှုပ်နှံမှုများ ဖန်တီးပေးခြင်းတို့ဖြင့်
									မြန်မာနိုင်ငံ၏ စွမ်းအင် ချို့တဲ့မှု ကိုအဆုံးသတ်နိုင်ရန်
									ဆောင်ရွက်လျက်ရှိသည်။
								</span>
							</p> -->
            </transition>
          </div>
          <!-- toggle original place -->
        </div>
        <div
          class="col-lg-3 col-md-4 pl-md-5 d-flex facts"
          v-touch:swipe="onSwipe"
        >
          <div v-scroll-ani class="scroll-ani top">
            <svg
              width="16"
              height="16"
              class="scroll-ani-circle"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="7" stroke="#EFEEEE" stroke-width="2" />
            </svg>

            <svg
              width="2"
              height="90"
              class="scroll-ani-line"
              viewBox="0 0 2 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="4.37114e-08"
                x2="0.999996"
                y2="100"
                stroke="#EFEEEE"
                stroke-width="2"
              />
            </svg>
          </div>

          <vueper-slides
            :dragging-distance="50"
            ref="whoweare"
            :touchable="false"
            class="w-100 no-shadow white-dots facts-slide"
            :arrows="false"
            :autoplay="true"
          >
            <vueper-slide
              v-for="item in filterFacts($route.name)"
              :title="item.title"
              :key="item.title"
            >
              <template #content>
                <div class="vueperslide__content-wrapper">
                  <div class="ml-4 fact" :key="item.item">
                    <p class="text-white title">
                      <span v-html="item.title"></span>
                    </p>
                    <p class="normal-text text-white">
                      <span v-html="item._content"></span>
                    </p>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <div
          class="
            col-lg-12
            bg-padding
            our-vision
            my-3 my-md-5
            pb-0
            px-lg-3 px-md-3
          "
        >
          <div class="row">
            <div class="col-lg-3 col-md-3">
              <p class="mt-3 number-label text-white">
                <span v-html="post['section1-title2']"></span>
              </p>
            </div>
            <div class="col-lg-9 col-md-9">
              <div class="row px-sm-2">
                <div class="col-lg-3 col-md-6 col-sm-6 mt-sm-4 px-sm-2">
                  <div class="bg-divider my-2"></div>
                  <p class="text-white lg-title">
                    <span v-counter v-html="post['val-1']"></span>
                  </p>
                  <p class="sub-title md-sm-sub-title text-white">
                    <span v-html="post['val-1-des']"></span>
                  </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-sm-4 px-sm-2">
                  <div class="bg-divider my-2"></div>
                  <p class="text-white lg-title">
                    <span v-counter v-html="post['val-2']"></span>
                  </p>

                  <p class="sub-title md-sm-sub-title text-white">
                    <span v-html="post['val-2-des']"></span>
                  </p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-sm-4 px-sm-2">
                  <div class="bg-divider my-2"></div>
                  <p class="text-white lg-title">
                    <span v-counter v-html="post['val-3']"></span>
                  </p>
                  <p class="sub-title md-sm-sub-title text-white">
                    <span v-html="post['val-3-des']"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 bg-padding pb-0 mb-md-5 px-3">
          <div class="row px-sm-2">
            <div class="col-12 px-sm-2">
              <div class="bg-divider mb-sm-2 mb-4 mt-0"></div>
            </div>
            <div class="col-lg-5ths col-md-5ths col-sm-12 mb-sm-4 px-sm-2">
              <p class="number-label text-white">
                <span v-html="post['section1-title3']"></span>
              </p>
            </div>
            <div
              class="col-lg-5ths col-md-5ths col-sm-6 mb-sm-3 px-sm-2 px-md-2"
            >
              <router-link :to="post['card1-link']" class="blur-img-box">
                <img v-lazy="post['card1-image'].url" class="w-100" alt="" />
                <p class="blur-text text-center">
                  <span v-html="post['card1-text']"></span>
                </p>
              </router-link>
            </div>
            <div
              class="col-lg-5ths col-md-5ths col-sm-6 mb-sm-3 px-sm-2 px-md-2"
            >
              <router-link :to="post['card2-link']" class="blur-img-box">
                <img v-lazy="post['card2-image'].url" class="w-100" alt="" />
                <p class="blur-text text-center">
                  <span v-html="post['card2-text']"></span>
                </p>
              </router-link>
            </div>
            <div
              class="col-lg-5ths col-md-5ths col-sm-6 mb-sm-3 px-sm-2 px-md-2"
            >
              <router-link :to="post['card3-link']" class="blur-img-box">
                <img v-lazy="post['card3-image'].url" class="w-100" alt="" />
                <p class="blur-text text-center">
                  <span v-html="post['card3-text']"></span>
                </p>
              </router-link>
            </div>
            <div
              class="col-lg-5ths col-md-5ths col-sm-6 mb-sm-3 px-sm-2 px-md-2"
              v-if="post['card4-link']"
            >
              <router-link :to="post['card4-link']" class="blur-img-box">
                <img v-lazy="post['card4-image'].url" class="w-100" alt="" />
                <p class="blur-text text-center">
                  <span v-html="post['card4-text']"></span>
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="section-1-scroll-ani md-hide sm-hide">
          <div v-scroll-ani class="scroll-ani top">
            <svg
              width="16"
              height="16"
              class="scroll-ani-circle"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="8"
                r="7"
                stroke="rgb(var(--secondary))"
                stroke-width="2"
              />
            </svg>

            <svg
              width="2"
              height="90"
              class="scroll-ani-line"
              viewBox="0 0 2 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="4.37114e-08"
                x2="0.999996"
                y2="100"
                stroke="rgb(var(--secondary))"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 who-we-are-section-2 bg3 pt-md-0 bg-padding"
      id="our-team"
      v-toggle-header="{
        header: 'light-header-our-members',
        logo: 'dark-logo-our-members',
      }"
    >
      <div class="border-secondary mb-sm-2 my-md-5"></div>
      <members :title="post['our-team-title']" :des="post['our-team-des']" />
    </div>
    <div
      class="who-we-are-section-3 bg1 bg-padding col-lg-12"
      id="our-partners"
      v-toggle-header="{
        header: 'light-header-our-partners',
        logo: 'dark-logo-our-partners',
      }"
    >
      <div class="row pt-md-5">
        <div class="col-lg-3 col-md-3 bg-padding pt-md-3 py-0 pl-3">
          <div class="border-secondary lg-hide md-hide mb-sm-2"></div>
          <p class="lg-title primary">
            <span v-html="post['our-partners-title']"></span>
          </p>
          <p class="normal-text mt-sm-5 mt-3">
            <span v-html="post['our-partners-des']"></span>
          </p>
        </div>
        <div class="col-lg-9 col-md-9">
          <div class="border-secondary sm-hide mt-0"></div>
          <div class="row">
            <div class="col-lg-6 my-sm-4 col-md-8 col-sm-8">
              <p>Supported by:</p>
              <div class="img-box supported">
                <a
                  href="https://www.rockefellerfoundation.org/commitment/power/"
                  target="_blank"
                >
                  <img
                    v-lazy="'image/Rockerfelle_foundations.png'"
                    class="w-100 supported-img"
                    alt=""
                  />
                </a>
                <a href="https://www.energyalliance.org/" target="_blank">
                  <img
                    v-lazy="'image/geapp-support.png'"
                    class="w-100 supported-img"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 my-sm-4 col-sm-4">
              <p>Managed by:</p>
              <div class="img-box img-box-2">
                <a
                  href="https://www.pactworld.org/"
                  target="_blank"
                  class="h-100 flex-center"
                  rel="noopener noreferrer"
                >
                  <img v-lazy="'image/manage.png'" class="managed-img" alt=""
                /></a>
              </div>
            </div>
            <div class="col-lg-4 advisors col-md-10">
              <p class="pl-3 pl-sm-0">Our advisors:</p>
              <div class="flex-wrap pl-sm-0 img-box-wrap">
                <!-- <a
                  href="https://www.hamaragrid.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img v-lazy="'image/hamara.png'" alt=""
                /></a> -->
                <a
                  href="https://andamancapitalpartners.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img v-lazy="'image/advisor-1.jpg'" alt=""
                /></a>
                <a
                  href="https://www.vdb-loi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img v-lazy="'image/advisor-2.png'" alt="" /></a
                ><a
                  href="https://www.bridge.com.mm/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img v-lazy="'image/advisor-3.png'" alt=""
                /></a>
              </div>
            </div>
          </div>
          <div class="border-secondary my-sm-3 mt-5"></div>
          <div class="row">
            <div class="col-lg-12">
              <p class="normal-text">Our coalition:</p>
              <div class="logo-box">
                <a
                  class="coalition-logo"
                  v-for="logo in coalitionLogos"
                  :key="logo.url"
                  :href="logo.link"
                  target="_blank"
                >
                  <img :src="logo.url" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 who-we-are-section-4 bg-padding"
      :style="`background:url(${post['our-back-bg'].url}) no-repeat`"
      id="our-backstory"
    >
      <div class="row">
        <div class="col-lg-6 col-md-7"></div>
        <div class="col-lg-6 col-md-5 bg-padding">
          <div class="bg-padding pt-0 pl-0">
            <p class="secondary lg-title">
              <span v-html="post['our-backstory-title']"></span>
            </p>
            <p class="normal-text mt-sm-5 mt-3 text-white">
              <span v-html="post['our-backstory-des']"></span>
            </p>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="w-100 blur-bg">
            <div class="row">
              <div
                class="
                  col-lg-6 col-md-6
                  right-divider
                  border-primary
                  our-backstory
                  ob-1
                "
              >
                <div class="row">
                  <div
                    class="
                      col-lg-4 col-md-4
                      flex-center
                      who-we-are_ourBackstory-img
                    "
                  >
                    <img
                      :src="post['our-b1-logo'].url"
                      style="height: 65px; object-fit: contain"
                      class="w-100"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-8 pl-md-0 col-md-8 pr-4 bottom-divider">
                    <p class="md-xs-text">
                      <span v-html="post['our-b1-des']"></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="sm-divider md-hide lg-hide sm-hide"></div>
              <div class="col-lg-6 col-md-6 our-backstory ob-2">
                <div class="row h-100">
                  <div
                    class="
                      col-lg-3 col-md-3
                      flex-center
                      who-we-are_ourBackstory-img
                    "
                  >
                    <img
                      :src="post['our-b2-logo'].url"
                      style="height: 65px; object-fit: contain"
                      class="w-100"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-9 pl-md-0 flex-center col-md-9">
                    <p class="md-xs-text">
                      <span v-html="post['our-b2-des']"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
// import Toggle from '@vueform/toggle';
import members from "../common/members.vue";
import Footer from "../common/footer.vue";
import { mapGetters } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";
const coalition = [
  {
    href: "https://mcb.com.mm/",
    img: "/image/coalition/mcb.png",
  },
  {
    href: "https://www.afd.fr/en",
    img: "/image/coalition/AFD_0 1.png",
  },
  {
    href: "https://www.giz.de/en/html/index.html",
    img: "/image/coalition/giz-logo 1.png",
  },
  {
    href: "https://www.facebook.com/subsidiaryofsunpowercompanylimited/",
    img: "/image/coalition/Mango Energy_Logo 1.png",
  },
  {
    href: "https://yomamandalay.com",
    img: "/image/coalition/Mandalay Yoma.png",
  },
  {
    href: "http://spsolarstation.com/",
    img: "/image/coalition/Sun Power.png",
  },
  {
    href: "https://www.tfe.energy/",
    img: "/image/coalition/TFE Energy.png",
  },
  {
    href: "https://www.smartermicrogrid.com/",
    img: "/image/coalition/Smarter Microgrid.png",
  },

  {
    href: "https://www.kckm.biz/en/",
    img: "/image/coalition/KCKM.png",
  },
  {
    href: "https://www.hanamyanmar.com/",
    img: "/image/coalition/Hana.png",
  },
  {
    href: "https://www.odysseyenergysolutions.com/",
    img: "/image/coalition/logo-odyssey-black 1.png",
  },
  {
    href: "http://www.megaglobalgreen.com/",
    img: "/image/coalition/MGGA 1.png",
  },
  {
    href: "https://www.techno-hill.com",
    img: "/image/coalition/Techno-Hill Engineering 1.png",
  },
  {
    href: "https://www.facebook.com/ZLT-Super-Electrical-CoLtd-103433464348895/",
    img: "/image/coalition/ZLT Super Electrical.png",
  },
  {
    href: "http://www.pecmyanmar.com/",
    img: "/image/coalition/ProEngineer_Logo 1.png",
  },
  {
    href: "http://talentntech.com/",
    img: "/image/coalition/Talent and Technology logo.png",
  },
  {
    href: "https://www.meepanyar.com/",
    img: "/image/coalition/FullLogo_MM 1.png",
  },
  {
    href: "https://www.facebook.com/renewableinnovativeenergy/",
    img: "/image/coalition/Innovative Energy 1.png",
  },
  {
    href: "https://www.jica.go.jp/english/index.html",
    img: "/image/coalition/JicaLogo.png",
  },
  {
    href: "https://www.mref.asia/",
    img: "/image/coalition/MREF Logo 1.png",
  },
];

import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Who We Are",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      isMM: false,
      coalition: coalition,
      getting: true,
      post: {},
      dyHeight: 0,
      coalitionLogos: [],
    };
  },
  components: {
    // Toggle,
    members,
    Footer,
    VueperSlides,
    VueperSlide,
  },
  created() {
    setTimeout(() => {
      const lang = document.getElementsByClassName("lang-text");
      if (lang[0]) {
        this.dyHeight = lang[0].clientHeight + "px !important";
      }
    }, 100);
    this.getData();
  },
  mounted() {
    const url = new URL(window.location);
    if (url.hash) {
      this.$store.commit("scrollToHash", url);
    }
  },
  computed: {
    ...mapGetters(["isLg", "filterFacts"]),
  },
  watch: {
    isMM() {
      const lang = document.getElementsByClassName("lang-text");
      this.dyHeight = lang[0].clientHeight + "px !important";
    },
    post() {
      setTimeout(() => {
        const lang = document.getElementsByClassName("lang-text");
        this.dyHeight = lang[0].clientHeight + "px !important";
      }, 350);
    },
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("who-we-are-page");
      if (cacheData) {
        this.post = cacheData[0];
        var oldCoalitionLogos = [];
        this.coalitionLogos = [];
        for (var key in this.post["our-coalition"]) {
          oldCoalitionLogos.push(this.post["our-coalition"][key]);
        }
        this.coalitionLogos = oldCoalitionLogos;

        this.getting = false;
        const lang = document.getElementsByClassName("lang-text");
        if (lang[0]) this.dyHeight = lang[0].clientHeight + "px !important";
        this.$store.commit("getData");
      }
      this.$get.getFreshData("who-we-are-page").then((data) => {
        this.post = data[0];
        var coalitionLogos = [];
        this.coalitionLogos = [];
        for (var key in this.post["our-coalition"]) {
          coalitionLogos.push(this.post["our-coalition"][key]);
        }
        this.coalitionLogos = coalitionLogos;
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    onSwipe(direction) {
      if (direction === "right") {
        this.$refs.whoweare.previous();
      } else if (direction === "left") {
        this.$refs.whoweare.next();
      }
    },
  },
};
</script>

<style scoped>
.lang .toggle-container {
  position: absolute;
  bottom: 0px;
}
.blur-bg {
  padding: 40px;
  background: rgba(239, 238, 238, 0.8);
  backdrop-filter: blur(10px);
}
.logo-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.coalition-logo {
  padding: 0px 15px;
  max-width: 145px;
  display: flex;
  align-items: center;
  overflow: visible;
  margin-bottom: 20px;
}
.coalition-logo img {
  height: 45px;
  width: 100%;
  object-fit: contain;
}
.flex-wrap.img-box-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-top: 15px;
  position: relative;
  padding-left: 5px;
}
.flex-wrap.img-box-wrap img {
  height: 50px;
  max-width: 90px;
  object-fit: contain;
}
.section-1-scroll-ani {
  position: absolute;
  bottom: -35px;
  margin-left: 15px;
}
.who-we-are-section-3 .img-box {
  height: 80px;
  display: flex;
  align-items: center;
  border-right: 1px solid rgb(var(--secondary));
  padding-right: 15px !important;
  padding-left: 0px !important;
  margin-top: 15px;
}
.who-we-are-section-3 .img-box img {
  height: 100%;
}
.blur-img-box {
  padding: 20px;
  background: rgba(239, 238, 238, 0.6);
  backdrop-filter: blur(10px);
  height: 100%;
  transition: 0.3s all ease-in-out;
  display: block;
}
.blur-img-box:hover {
  background: rgb(var(--secondary));
  color: rgb(var(--white));
}
.blur-img-box img {
  margin-bottom: 10px;
}
/* .who-we-are-section-1 {
  padding-top: calc(4.5rem + 100px);
  position: relative;
  background: url("/image/who-we-are-bg-min.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  will-change: transform;
  background-attachment: fixed;
} */

.who-we-are-section-4 {
  position: relative !important;
  background-size: cover !important;
  will-change: transform !important;
  /* z-index: -1; */
  background-position: 75% 0% !important;
  background-color: #fff !important;
}
/* .who-we-are-section-4:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/image/section-3.jpg") no-repeat;
  background-size: cover;
  will-change: transform;
  z-index: -1;
  background-position: 75% 0%;
} */
.fix-bg {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0px;
  background-position: center;
  /* background: url("/image/who-we-are-bg-min.jpg") no-repeat; */
  will-change: transform;
  z-index: -10;
  bottom: 0px;
  background-size: cover !important;
}

.who-we-are-section-1:after {
  content: "";
  background: linear-gradient(180deg, rgb(var(--primary)), transparent);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
}
.supported img.supported-img {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  max-width: 280px !important;
  height: 70px;
}
.col-lg-9.pl-md-0.col-md-9.pr-4.bottom-divider {
  padding-bottom: 20px;
}
.col-lg-3.col-md-3.flex-center > img {
  margin-bottom: 10px;
}
.sm-divider {
  padding: 5rem 0;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid rgb(var(--secondary));
  margin-bottom: 20px;
}
/* ------------------------------------------------'mobile'------------------------------------------------ */
@media screen and (max-width: 767px) {
  .our-backstory {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 2rem 0 2rem;
  }
  .blur-bg img {
    height: 40px !important;
  }
  .who-we-are_ourBackstory-img {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1210px) {
  .blur-bg img {
    height: 60px !important;
  }
}
</style>
