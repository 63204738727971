<template>
  <div class="row">
    <transition name="fade">
      <div class="noti" :key="message" v-if="showNoti">
        <p v-html="message"></p>
      </div>
    </transition>

    <div class="col-lg-12 login-page px-0 d-flex flex-center">
      <img :src="post['background'].url" class="w-100" alt="" />
      <div class="login-container-box flex-column flex-center">
        <p class="title login-title">
          <span v-html="post.title"></span>
        </p>

        <div class="login-box mt-sm-0 row bg-padding">
          <form @submit.prevent="login" class="col-12 px-sm-0 w-100">
            <div class="row">
              <div class="col-lg-4 col-md-4 mt-3 col-sm-12">
                <input
                  type="text"
                  v-model="username"
                  class="w-100 input normal-text"
                  placeholder="Username"
                />
              </div>
              <div class="col-lg-4 col-md-4 mt-3">
                <input
                  type="password"
                  v-model="password"
                  class="w-100 input normal-text"
                  autocomplete
                  placeholder="Password"
                />
              </div>
              <div class="col-lg-4 col-md-4 mt-3">
                <button
                  type="submit"
                  class="input secondary-bg text-white normal-text w-100"
                >
                  <p v-if="!loading">Login</p>

                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="white"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    class="loading-icon"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                  </svg>
                </button>
              </div>
            </div>
          </form>
          <div class="col-lg-12 px-sm-0 flex-center mt-4">
            <p class="normal-text sm-xs-text login-info text-center">
              <span v-html="post.note"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Login",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      showNoti: false,
      post: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("login");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("login").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    login() {
      this.loading = true;
      this.$axios
        .post("https://backend.smartpowermyanmar.org/wp-json/jwt/login", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          this.loading = false;
          if (JSON.stringify(res.data).includes("Error")) {
            this.showNoti = true;
            this.message = res.data;
            setTimeout(() => {
              this.showNoti = !this.showNoti;
            }, 5000);
          } else {
            this.$store.commit("setUser", res.data.display_name);
            var uid = btoa(res.data.id);
            this.setCookie("user", res.data.display_name, 30);
            this.setCookie("uid", uid, 30);
            this.setCookie("isLoggedIn", true, 30);
            const redirect = localStorage.getItem("redirect");
            if (redirect) {
              this.$router.push(redirect);
              localStorage.removeItem("redirect");
            } else {
              this.$router.push({ name: "Member Portal" });
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setCookie(cname, cvalue, minutes) {
      var now = new Date();
      now.setTime(now.getTime() + minutes * 60 * 1000);
      var expires = "; expires=" + now.toGMTString();
      document.cookie = cname + "=" + cvalue + expires + "; path=/";
    },
  },
};
</script>

<style >
.login-page {
  height: 100vh;
  overflow: hidden;
}
.input {
  height: 38px;
  background: transparent;
  border: 1px solid rgb(var(--secondary));
  text-align: center;
}
.input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(var(--text));
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(var(--text));
}

.input::placeholder {
  color: rgb(var(--text));
}
.login-page img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;

  height: 100vh;
}
.login-box {
  width: 60vw;
  border: 1px solid rgb(var(--secondary));
  margin-top: 2.5rem;
  padding: 70px 25px;
}
.login-info {
  width: 90%;
}
</style>