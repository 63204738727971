<template>
  <div class="accordion-box" role="presentation">
    <div
      class="accordion-item"
      :class="item.title == modelValue && 'is-active'"
      v-for="item in content"
      :key="item.title"
    >
      <div class="accordion-item-title">
        <button @click="toggle(item.title)" class="accordion-item-trigger">
          <p class="sub-title md-sm-sub-title text-bold">
            <span v-html="item.title"></span>
          </p>
          <span class="accordion-item-trigger-icon"></span>
        </button>
      </div>
      <transition
        name="accordion"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:before-leave="beforeLeave"
        v-on:leave="leave"
      >
        <div v-if="item.title == modelValue" class="accordion-item-details">
          <img v-lazy="item.image.url" class="w-100" v-if="!isLg" alt="" />
          <div
            v-for="(text, index) in item.des"
            :key="index"
            :class="
              index == `item-${Object.keys(item.des).length - 1}`
                ? 'mb-4'
                : 'my-2'
            "
          >
            <p
              v-if="text.type !== 'link'"
              class="normal-text"
              :style="`color:${text.color}`"
            >
              <span v-html="text.text"></span>
            </p>
            <a
              :href="text.link"
              :style="`color:${text.color}`"
              class="normal-text underline-text"
              v-else
            >
              <span v-html="text.text"></span>
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    content: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      windowWidth : document.documentElement.clientWidth,
      windowHeight : document.documentElement.clientHeight,
    };
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
  },
  methods: {
    toggle(item) {
      if (this.modelValue == item) {
        item = null;
      }
      this.$emit("update:modelValue", item);
    },

    beforeEnter: function (el) {
      el.style.height = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
    setDimensions() {
        this.windowWidth = document.documentElement.clientWidth;
        this.windowHeight = document.documentElement.clientHeight;
    },
    beforeDestroy() {
    window.removeEventListener('resize', this.setDimensions);
    },
  },
  
  
  computed: {
    ...mapGetters(["isLg"]),
  },
};
</script>


<style scoped>
.accordion-item-title {
  position: relative;
}
.accordion-item-details img {
  height: calc(100vw / 2);
  width: 100%;
  object-fit: cover;
  margin: 10px 0px;
}
.accordion-item {
  border-top: 1px solid rgb(var(--text));
}
.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 10px 0px !important;
  transition: 1.2s all ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(var(--text)) !important;
}

.accordion-item-trigger-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-right: 2px solid rgb(var(--text));
  border-bottom: 2px solid rgb(var(--text));
  transform: translateY(-2px) rotate(45deg);
  transition: transform 0.4s ease-in-out;
}
.is-active .accordion-item-trigger-icon {
  transform: translateY(2px) rotate(225deg);
}

.accordion-item-details {
  overflow: hidden;
  transition: 0.4s all ease-in-out;
}
.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}

.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>