export default {
  install(Vue) {
    Vue.directive("parallex", (el) => {
      const sp = () => {
        let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        let x = scrollTop / 50;
        let y = Math.round(x * 100) / 100;
        el.style.transform = `translate3d(0px, ${y}px, 0px)`;
        requestAnimationFrame(sp);
      };
      requestAnimationFrame(sp);
    });
  },
};
