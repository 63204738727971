<template>
  <div class="row primary-bg" v-if="!getting">
    <div class="header-height"></div>
    <div class="col-lg-12 mb-sm-3 bg-padding">
      <div class="row">
        <div class="col-lg-3 col-md-3">
          <p class="lg-title secondary mb-sm-5 text-white md-title">
            <span v-html="post['section1-title']"></span>
          </p>
        </div>
        <div class="col-lg-6 col-md-9">
          <p class="sm-sub-title mb-3 text-white">
            <span v-html="post['section1-p1']"></span>
          </p>
          <p class="sm-sub-title secondary">
            <span v-html="post['section1-p2']"></span>
          </p>
        </div>
        <div class="col-lg-3 d-flex"></div>
      </div>
    </div>
    <maps />
    <div class="col-lg-12 primary-bg bg-padding">
      <div class="row">
        <div class="col-lg-5 col-md-4">
          <p class="title text-white mb-sm-4 md-sm-title">
            <span v-html="post['section3-title']"></span>
          </p>
        </div>
        <div class="col-lg-7 col-md-8">
          <p class="sm-sub-title mb-sm-4 text-white">
            <span v-html="post['section3-p']"></span>
            <a class="secondary" :href="`mailto:${post['section3-email']}`">{{
              post["section3-email"]
            }}</a>
          </p>
        </div>
      </div>
    </div>
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
import Footer from "../common/footer.vue";
import maps from "../common/maps.vue";
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Member Portal",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      post: {},
      getting: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("member-portal");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("member-portal").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
  },
  components: { maps, Footer },
};
</script>

<style scoped>
</style>