<template>
  <div class="business-card">
    <div class="card-border" :style="`background:${color}`"></div>
    <p
      class="sub-title card-title pointer"
      :style="`color:${color}`"
      style="width: 95%"
    >
      {{ data.post_title }}
    </p>
    <p class="my-2 sm-text">
      <span
        @click="
          $router.push({
            name: 'Business Intelligence',
            query: {
              tag: tag.slug,
            },
          })
        "
        class="pointer"
        v-for="(tag, index) in data.tags['cate-tags']"
        :key="index"
        ><span v-html="tag.name"></span>
        <span v-if="index !== data.tags['cate-tags'].length - 1">
          |
        </span></span
      >
    </p>
    <div class="d-flex" :class="!data.tags['cate-tags'] && 'mt-3'">
      <a
        class="mr-3 underline sm-text"
        target="_blank"
        :href="data.meta['myanmar-file']"
        v-if="data.meta['myanmar-file']"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
        >Myanmar
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.64285 0.652344V9.66303L1.46829 7.31585L0.760277 8.09824L4.84599 11.1228L5.2 11.3854L5.55401 11.1228L9.63972 8.09824L8.9317 7.31585L5.75714 9.66303V0.652344H4.64285ZM0 11.4064V12.4146H10.4V11.4064H0Z"
            fill="#338CAB"
          />
        </svg>
      </a>
      <a
        :href="data.meta['english-file']"
        v-if="data.meta['english-file']"
        target="_blank"
        class="underline mr-3 sm-text"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
      >
        English
        <svg
          width="11"
          height="13"
          viewBox="0 0 11 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.64285 0.652344V9.66303L1.46829 7.31585L0.760277 8.09824L4.84599 11.1228L5.2 11.3854L5.55401 11.1228L9.63972 8.09824L8.9317 7.31585L5.75714 9.66303V0.652344H4.64285ZM0 11.4064V12.4146H10.4V11.4064H0Z"
            fill="#338CAB"
          /></svg
      ></a>
      <p
        v-if="
          data.post_content &&
          !data.meta['read-more'] &&
          (!data.meta['english-file'] || !data.meta['english-file'])
        "
        class="sm-text pointer mr-3 underline"
        @click="
          $router.push({
            name: 'Post',
            params: {
              name: data.post_name,
              id: data.ID,
            },
          })
        "
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
      >
        Read more
      </p>
      <a
        v-if="
          data.meta['read-more'] &&
          (!data.meta['english-file'] || !data.meta['english-file'])
        "
        class="sm-text pointer mr-3 underline"
        :href="data.meta['read-more']"
        target="_blank"
        :style="`color:${
          color == '#0D2E4A' || color == '#0d2e4a' || color == 'rgb(13, 46, 74)'
            ? `rgb(var(--secondary))`
            : color
        }`"
      >
        Read more
      </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {},
    color: {
      type: String,
      default: "rgb(var(--lighter-primary))",
    },
  },
  data() {
    return {
      getting: true,
    };
  },
  watch: {},
  mounted() {
    this.sortArray();
  },
  methods: {
    sortArray() {
      var tags = this.data.tags["cate-tags"];
      if (tags)
        tags.sort(function (a, b) {
          var dateA = moment(a.name);
          var dateB = moment(b.name);

          if (dateA.isValid()) {
            return 1;
          }
          if (dateB.isValid()) {
            return -1;
          }
          return 0;
        });
    },
  },
};
</script>

<style scoped>
.business-card {
  padding: 0px 25px;
  position: relative;
}
.card-border {
  width: 3%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 5px;
}
</style>