export default () => {
  const getClientHeight = () => document.documentElement.clientHeight;
  const isInScrollView = ({ top, bottom }) =>
    top < getClientHeight() && bottom > 0;

  const applyAnimationClass = (el, { isUpwards }) => {
    const removeClass = isUpwards ? "bottom" : "top";
    const animationClass = isUpwards ? "top" : "bottom";
    el.classList.add(animationClass);
    el.classList.remove(removeClass);
  };

  return {
    isInView: isInScrollView,
    run(el, { isUpwards }) {
      if (!this.isInView(el.getBoundingClientRect())) {
        return;
      }
      return applyAnimationClass(el, { isUpwards });
    },
  };
};
