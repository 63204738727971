// import axios from "axios";
import { url } from "../constant/data";
import { setup } from "axios-cache-adapter";
const axios = setup({
  baseURL: url,
  maxAge: 100000 * 60 * 1000,
  cache: {
    // Tell adapter to attempt using response headers
    readHeaders: true,
    // For this example to work we disable query exclusion
    exclude: { query: false },
  },
});

export default axios;
