<template>
  <div class="row" v-if="!getting">
    <div class="header-height"></div>
    <transition name="fade">
      <div class="noti" :key="message" v-if="showNoti">
        <p v-html="message"></p>
      </div>
    </transition>
    <div class="col-lg-12 bg-padding">
      <div class="row">
        <div class="col-lg-3 mb-sm-5 col-md-3">
          <p class="lg-title md-title secondary sm-md-5">
            <span v-html="post['title']"></span>
          </p>
        </div>
        <div class="col-lg-9 col-md-9">
          <p class="sm-sub-title">
            <span v-html="post['paragraph']"></span>
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-12 mt-5 pt-0 bg-padding">
      <div class="top-border-primary mt-0 mb-md-5 bg-m mx-0"></div>
      <div class="row pb-md-5 pt-md-3 mb-sm-5">
        <div class="col-lg-6 col-md-6">
          <p class="sub-title mb-1"><span v-html="post['label-1']"></span></p>
          <span v-html="post['des-1']"></span>
          <p class="sub-title mb-1"><span v-html="post['label-2']"></span></p>
          <span v-html="post['des-2']"></span>
          <p class="sub-title mb-1 mt-5 mt-sm-4">
            <span v-html="post['label-3']"></span>
          </p>
          <span v-html="post['des-3']"></span>
          <p class="sub-title mb-1 mt-5 mt-sm-4">
            <span v-html="post['label-4']"></span>
          </p>
          <div class="flex-align-center">
            <a
              href="https://www.facebook.com/smartpowermyanmar"
              target="_blank"
            >
              <svg
                width="42"
                height="42"
                class="mr-3"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.2891 20.645C41.2891 9.24186 32.0474 0 20.6445 0C9.24165 0 0 9.24186 0 20.645C0 32.0481 9.24165 41.29 20.6445 41.29C20.7655 41.29 20.8865 41.29 21.0074 41.2819V25.2175H16.5721V20.0482H21.0074V16.2418C21.0074 11.8306 23.7009 9.42735 27.6363 9.42735C29.5233 9.42735 31.1442 9.56444 31.6119 9.62896V14.2418H28.9023C26.7653 14.2418 26.346 15.2579 26.346 16.7499V20.0402H31.4668L30.7974 25.2095H26.346V40.4916C34.9747 38.0158 41.2891 30.0723 41.2891 20.645Z"
                  fill="#DC5F40"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/smart-power-myanmar/"
              target="_blank"
            >
              <svg
                width="43"
                height="42"
                viewBox="0 0 43 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5273 0C10.1274 0 0.882812 9.24482 0.882812 20.645C0.882812 32.0452 10.1274 41.29 21.5273 41.29C32.9273 41.29 42.1719 32.0452 42.1719 20.645C42.1719 9.24482 32.9273 0 21.5273 0ZM15.5283 31.2094H10.5004V16.0826H15.5283V31.2094ZM13.0145 14.017H12.9817C11.2945 14.017 10.2033 12.8556 10.2033 11.404C10.2033 9.91959 11.3279 8.79025 13.0479 8.79025C14.7678 8.79025 15.8263 9.91959 15.859 11.404C15.859 12.8556 14.7678 14.017 13.0145 14.017ZM33.6578 31.2094H28.6305V23.1169C28.6305 21.0832 27.9025 19.6962 26.0833 19.6962C24.6945 19.6962 23.8672 20.6318 23.5037 21.5349C23.3708 21.8581 23.3383 22.3099 23.3383 22.7619V31.2094H18.3108C18.3108 31.2094 18.3766 17.5018 18.3108 16.0826H23.3383V18.2244C24.0065 17.1937 25.2019 15.7276 27.8695 15.7276C31.1774 15.7276 33.6578 17.8895 33.6578 22.5357V31.2094Z"
                  fill="#DC5F40"
                />
              </svg>
            </a>
          </div>
        </div>
        <div class="col-lg-6 mt-sm-5 col-md-6" v-if="!success">
          <p class="sub-title md-title sm-email-title">
            Want to receive our business intelligence directly?
          </p>
          <p class="sm-text secondary mb-5 mb-sm-4">
            Please fill out this form.
          </p>
          <form ref="form" class="email-form" @submit.prevent="sendEmail">
            <input required type="text" name="your-name" placeholder="Name" />
            <input
              required
              type="text"
              name="your-job"
              placeholder="Job Title"
            />
            <input
              required
              type="text"
              name="your-organisation"
              placeholder="Organisation"
            />
            <input
              required
              type="email"
              name="your-email"
              placeholder="Email"
            />
            <input
              required
              type="number"
              name="your-number"
              placeholder="Contact number"
            />
            <div class="row">
              <div
                class="col-lg-6 col-md-12 col-sm-12"
                style="
                  justify-content: flex-end !important;
                  display: flex;
                  flex-direction: column;
                "
              >
                <div
                  class="g-recaptcha"
                  data-sitekey="6LepJJkcAAAAAK6kK17NFIms8t5-VGH3bytOLS-s"
                ></div>
                <button
                  type="submit"
                  class="button secondary-bg mt-md-4 text-white normal-text"
                >
                  <p v-if="!sending">Send</p>

                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="white"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    class="loading-icon"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                  </svg>
                </button>
              </div>
              <div class="col-6 md-hide sm-hide">
                <svg
                  width="90%"
                  class="mt-4 mx-4"
                  viewBox="0 0 469 238"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M136.797 1H306.989L328.715 24.1016V236.985H140.686L136.797 1Z"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M306.99 1V24.3702L328.717 24.1016"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M166.84 88.4375H261.793"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M166.84 135.445H219.145"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M165.9 151.025H211.499"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M166.84 119.732H282.849"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M166.84 199.916H249.455"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M166.84 104.15H239.128"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M185.615 49.7541C191.17 49.7541 195.674 45.2441 195.674 39.6808C195.674 34.1174 191.17 29.6074 185.615 29.6074C180.06 29.6074 175.557 34.1174 175.557 39.6808C175.557 45.2441 180.06 49.7541 185.615 49.7541Z"
                    stroke="#DC5F40"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M202.246 66.2762C202.246 57.143 194.87 49.7559 185.75 49.7559C176.63 49.7559 169.254 57.143 169.254 66.2762V72.4545H202.38L202.246 66.2762Z"
                    stroke="#DC5F40"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M136.798 33.7715L0.939453 97.0322L65.3146 237.253"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.717 33.2344L468.062 98.1068L403.151 235.776"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M43.0508 127.79L125.263 90.9883"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M48.6836 140.013L99.379 117.18"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M54.1836 152.101L85.5665 137.998"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M59.8164 164.189L75.642 157.07"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M65.3145 176.277L92.4057 164.189"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M70.8125 188.498L116.814 167.814"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M76.4453 200.587L117.082 182.32"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M81.9453 212.675L111.987 199.244"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 85.4824L393.36 115.568"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 98.2422L374.986 119.732"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 111.002L349.236 120.538"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 149.146L353.393 160.697"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 161.904L349.236 171.44"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M328.582 174.664L343.335 181.38"
                    stroke="#0D2E4A"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M94.8053 135.62L89.6738 149.9L157.942 174.504L163.073 160.224L94.8053 135.62Z"
                    fill="white"
                    stroke="#DC5F40"
                    stroke-miterlimit="3.9999"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M197.016 186.216L158.391 173.725L162.951 161.1L200.637 176.008L197.016 186.216Z"
                    stroke="#DC5F40"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M197.016 186.216L200.637 176.143L212.975 186.216H197.016Z"
                    stroke="#DC5F40"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M89.8577 149.817C85.9684 148.339 83.9567 144.041 85.2978 140.146C86.639 136.251 91.0648 134.237 94.9541 135.58"
                    stroke="#DC5F40"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M98.3066 136.923C99.5137 133.699 103.001 131.953 106.219 133.162L134.652 143.37L133.579 146.324"
                    stroke="#DC5F40"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.63281 236.986H463.367"
                    stroke="#0D2E4A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </form>
        </div>

        <div class="col-lg-6 col-md-6 flex-center" v-else>
          <div class="flex-align-center my-5">
            <svg
              width="70px"
              class="mx-3"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.3885 30.3672C53.643 16.4552 44.2092 3.34693 30.3175 1.0891C16.4258 -1.16873 3.33672 8.27885 1.08219 22.1909C-1.17234 36.1029 8.26144 49.2112 22.1531 51.469C36.0448 53.7269 49.1339 44.2793 51.3885 30.3672Z"
                fill="#DC5F40"
              />
              <path
                d="M15.7656 26.6806L23.0078 33.1275L36.6876 19.4277"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>

            <p class="sm-sub-title">
              You have successfully signed up to receive regular updates from
              us. Thank you for your support!
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer
      :data="[
        {
          name: post['footer-link-1-title'],
          url: post['footer-link-1'],
        },
        {
          name: post['footer-link-2-title'],
          url: post['footer-link-2'],
        },
      ]"
    />
  </div>
</template>

<script>
import Footer from "../common/footer.vue";
import { useMeta } from "vue-meta";
export default {
  setup() {
    useMeta({
      title: "Smart Power Myanmar | Contact Us",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  data() {
    return {
      post: {},
      sending: false,
      showNoti: false,
      message: "",
      success: false,
    };
  },
  components: {
    Footer,
  },
  created() {
    var script = document.createElement("script");
    script.id = "google-recaptcha-script";
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    this.getData();
  },
  beforeUnmount() {
    const check = document.getElementById("google-recaptcha-script");
    if (check) {
      check.remove();
    }
  },
  methods: {
    getData() {
      this.getting = true;
      const cacheData = this.$get.getCachedData("content-us");
      if (cacheData) {
        this.post = cacheData[0];
        this.getting = false;
        this.$store.commit("getData");
      }
      this.$get.getFreshData("content-us").then((data) => {
        this.post = data[0];
        this.getting = false;
        this.$store.commit("getData");
      });
    },
    sendEmail() {
      this.sending = true;
      const formData = new FormData(this.$refs.form);
      this.$axios
        .post(
          "https://backend.smartpowermyanmar.org/wp-json/contact-form-7/v1/contact-forms/401/feedback",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(
          (res) => {
            if (res.data.status !== "mail_failed") {
              this.showNoti = true;
              this.message = res.data.message;
              this.$refs.form.reset();
              this.success = true;
              setTimeout(() => {
                this.showNoti = !this.showNoti;
              }, 5000);
              this.sending = false;
            } else {
              this.showNoti = true;
              this.message = "Error : " + res.data.message;
              setTimeout(() => {
                this.showNoti = !this.showNoti;
              }, 5000);
              this.sending = false;
            }
          },
          (error) => {
            this.showNoti = true;
            this.message = "Error : " + error.text;
            setTimeout(() => {
              this.showNoti = !this.showNoti;
            }, 5000);
            this.sending = false;
          }
        );
    },
  },
};
</script>

<style scoped>
form.email-form {
  display: flex;
  flex-direction: column;
}
input {
  height: 38px;
  margin-bottom: 15px;
  padding: 0px 20px;
  background: transparent;
  border: 1px solid rgb(var(--secondary));
  border-radius: 0px !important;
  box-shadow: 0px !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(var(--text));
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(var(--text));
}

input::placeholder {
  color: rgb(var(--text));
}
.button {
  background: rgb(var(--secondary));
  color: #fff !important;
  width: 100%;
  margin-top: 38px;
  height: 38px;
}
.button:active {
  background: rgb(var(--secondary), 0.7);
  color: #fff !important;
}
</style>